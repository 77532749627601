@import '../assets/styles/_variables.sass'
@import '../assets/styles/_styles.sass'


.commentAdd
	margin-bottom: 16px

	&__header
		display: flex
		justify-content: space-between

	&__text
		width: 100%
		color: $primaryText
	&__footer
		text-align: right
		margin-top: -12px

	&__send
		cursor: pointer
		color: $primaryColor
		@include font20

	&__send:not(last-child)
		margin-right: 10px


.comments
	margin-top: 24px 
	padding: 24px


.comment
	display: flex
	padding: 16px 0px 0px
	border-bottom: 1px solid $secondaryLightPurple
	border-top: 1px solid $secondaryLightPurple

	&__avatar
		margin-right: 8px

	&__header
		display: flex
		margin-bottom: 8px
		align-items: center

	&__body
		width: 100%


	&__name
		@include font16
		color: $secondaryDarkGray
		margin-right: 16px

	&__me
		color: $primaryWhite
		padding: 2px 8px
		background: $secondaryDarkGray
		border-radius: 8px

	&__text
		margin-bottom: 8px
		color: $primaryText
		@include font16

		span 
			color: $primaryColor

	&__date
		@include font16
		color: $secondaryGray

	&__footer	
		margin-bottom: 16px
		cursor: pointer

	&__reply
		color: $primaryColor
		cursor: pointer
		margin-right: 24px
		@include font16

	&__repliesCount
			@include font16
			color: $secondaryGray
			display: inline-flex
			align-items: center
			
			svg
				width: 12px
				height: 6px
				margin-left: 10px 





.tabLine_name  
	display: flex 
	justify-content: left 
	align-items: center 

	font-style: normal 
	font-weight: 500 
	font-size: 2rem 
	line-height: 24px 
	color: var(--text_color) 


.tabLine_name_round  
	display: flex 
	flex-direction: row 
	justify-content: center 
	align-items: center 

	width: 56px 
	height: 56px 
	margin-right: 16px 

	background: $secondaryLightPurple 
	border-radius: 26px 

	font-style: normal 
	font-weight: 600 
	font-size: 2.4rem 
	line-height: 40px 
	text-align: center 
	color: $primaryColor 


.tabLine_name_round img  
	border-radius: 26px 
	overflow: hidden 
	width: 100%
	height: 100%
	object-fit: cover


@media (max-width: 768px)
	.tabLine_name_round
		width: 36px
		height: 36px
		font-size: 12px
		margin-right: 8px

	.commentAdd
		&__send
			font-size: 1.4rem
			line-height: 16px


	.commentAdd__text > div 
		margin-top: -4px
	.commentAdd__text > div > input
		height: 36px
		margin-bottom: 8px

	.commentAdd__text > div > p
		display: none

	.comments
		margin: 0px -24px
		border-radius: 0px
		margin-top: 12px

	.comment
		&__name
			font-size: 1.2rem
			line-height: 14px

		&__date
			font-size: 1.2rem	
			line-height: 14px

		&__header
			justify-content: space-between

		&__text
			font-size: 1.4rem	
			line-height: 16px

		&__reply
			font-size: 1.4rem	
			line-height: 16px

		&__repliesCount
			font-size: 1.4rem	
			line-height: 16px

		&:last-child
			border-bottom: none

		&__avatar
			margin-right: 0px
@import ../assets/styles/_variables

.wrapper
    padding: 24px 0
.card
    background: $primaryWhite
    box-shadow: $shadow
    border-radius: 24px
    padding: 24px
    height: fit-content
.progress
    height: 8px
    // max-width: 56px
    width: 100%
    border-radius: 4px
    background-color: $primaryHoverColor
    display: inline-block

    &_active
        background-color: $primaryColor
    &_selected
        box-shadow: 0px 2px 6px $primaryColor
    &__wrapper
        margin-bottom: 16px
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(10px,1fr))
        column-gap: 8px
.question
    font-size: 2rem
    line-height: 24px
    &__wrapper
        color: $primaryBlack
        padding-bottom: 16px
        border-bottom: 1px solid $secondaryLightPurple
        margin-bottom: 16px
    &__type

        margin-bottom: 8px
        display: inline-flex
        align-items: center
        color: $primaryBlack
        &>svg
            margin-right: 8px
        &>span
            font-size: 1.4rem
            line-height: 16px
    &__description
        font-size: 1.6rem
        margin-top: 8px
.button
    &__group
        display: flex
        justify-content: flex-end
    &__prev
        margin-right: 16px

.answers
    &__wrapper
        margin-bottom: 16px
.text
    font-size: 1.6rem
    line-height: 20px
    color: $primaryBlack
    margin-left: 8px
    &>b
        font-weight: 700
.choise
    display: flex
    align-items: center
    margin-bottom: 16px

    &>label
        border: 2px solid $secondaryGray
        border-radius: 12px
        min-width: 24px
    &:last-child
        margin-bottom: 0
    &:hover>label
        cursor: pointer
    &__wrapper
        display: flex
        flex-direction: column
div.checkbox
    margin-right: 0

.title
    font-size: 3.2rem
    line-height: 36px
    color: $primaryBlack
    margin-bottom: 4px
.date
    font-size: 1.6rem
    line-height: 20px
    color: $secondaryGray
    display: inline-flex
    align-items: center
    &>svg
        margin-right: 4px
    &.date_less
        color: $tertiaryRed
        &>svg
            path
                fill: $tertiaryRed
.header
    margin-bottom: 16px
    &__sub
        display: flex
        align-items: center
        gap: 24px
        font-size: 1.6rem
        line-height: 20px
        color: $secondaryGray
.container
    display: grid
    grid-template-columns: 2fr 1fr
    column-gap: 24px

.card__header
    padding-bottom: 16px
    margin-bottom: 16px
    border-bottom: 1px solid $secondaryLightPurple
    display: flex
    align-items: center
    &>svg
        margin-right: 8px
    &>span
        font-size: 2rem
        color: $secondaryDarkGray
.about
    font-size: 1.6rem
    line-height: 20px
    color: $primaryBlack

@media (min-width: 768px) and (max-width: 1180px)
    .container
        display: flex
        flex-direction: column
    .card
        margin-bottom: 24px
    .wrapper
        margin-bottom: -24px

@media (max-width: 768px)

    .progress
        height: 6px 

        &__wrapper 
            gap: 4px
    .container
        display: flex
        flex-direction: column
    .card
        order: 2
        border-radius: 20px
        margin-bottom: 16px

        &__header
            font-size: 1.6rem
            margin-bottom: 16px
            padding-bottom: 16px
            line-height: 18px

            svg
                width: 20px
                height: 20px
            & > span
                font-size: 1.6rem
    .desc
        margin-bottom: 16px

        padding: 20px 24px
    .about
        font-size: 1.4rem
        line-height: 18px

    .arrow
        position: relative
        border: 1.5px solid $primaryColor
        border-radius: 10px
        width: 36px
        height: 36px
        display: flex
        align-items: center
        justify-content: center

        & > button:hover
            background: none !important
            border: none

        svg
            path
                fill: $primaryColor

        &__disabled
            pointer-events: none
            background: $secondaryGray
            border-color: $secondaryGray

            svg
                path
                    fill: $secondaryDarkGray

    .arrow > button[type="submit"]
        width: 100%
        border-radius: 0px
        background: none
        height: 100%
        z-index: 2
        position: absolute

    .arrow:first-child
        margin-right: 16px

    .wrapper
        padding: 20px 0px 0px
    .title
        font-size: 2.4rem
        line-height: 32px
    .date 
        line-height: 14px

        svg
            width: 16px
            height: 16px

    .question
        font-size: 1.6rem
        line-height: 20px
        &__type > span
            font-size: 1.2rem
            line-height: 14px
        &__wrapper
            padding-bottom: 12px
            margin-bottom: 12px
    .text
        font-size: 1.4rem
        line-height: 18px
    .choise > label
        min-width: 20px
    .header 
        &__sub 
            font-size: 1.4rem
    .button
        &__group
            flex-direction: column
            row-gap: 8px
        &__prev
            margin-right: 0px
@import ../assets/styles/_variables

.load
    font-size: 6rem
    position: fixed
    top: calc(50% - 100px)
    left: calc(50% - 100px)
    transform: translate(-50%,-50%)
    z-index: 101
    width: 200px
    height: 200px
    animation: rotate 1s linear infinite 
.overlay
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    background-color: rgba(35,34,38,0.4)
    z-index: 100
    background-color: $secondaryBackground
.login
    /*background-color: #E2E0FF*/
@keyframes rotate
  0%
    transform: rotate(0)
  100% 
    transform: rotate(360deg)
.preloader
    &__mini
        animation: preloader-rotate 1s infinite linear
        width: 24px
        height: 24px
        path
            fill: $primaryColor !important

@keyframes preloader-rotate
    100%
        transform: rotate(360deg)
@import '../assets//styles/_variables.sass'

.faq
	&__title
		font-size: 3.2rem
		margin: 24px 0px
		color: $primaryText
	&__questions
		margin-bottom: 24px

		h2
			font-size: 32px
			line-height: 40px
			font-weight: 700 

	&__item
		cursor: pointer
		margin-bottom: 24px

		&:last-child
			margin-bottom: 0px

	&__question
		display: flex
		justify-content: space-between

		h3
			font-size: 20px
			line-height: 24px
			margin-bottom: 16px

	&__answer 
		h1, h2, h3, h4, h5, h6
			color: $secondaryDarkGray !important
		p
			font-size: 16px
			line-height: 20px
			color: $secondaryDarkGray

	&__open
		svg
			path
				fill: $secondaryGray

 

	&__contacts
		p 
			color: $secondaryDarkGray
			font-size: 20px
			line-height: 24px
			margin-bottom: 8px	
		p.faq__contact
			color: $primaryColor
			&:not(:last-child)
				margin-bottom: 24px 
		a
			margin-bottom: 8px
			color: $secondaryDarkGray
			font-size: 20px
			line-height: 24px
		a.faq__contact
			color: $primaryColor
			margin-bottom: 24px
			text-decoration: none
			display: block

			&:last-child
				margin-bottom: 0px

		h2
			font-size: 32px
			margin-bottom: 24px
			color: $secondaryDarkGray
  

.card
	background: $primaryWhite
	box-shadow: 0px 4px 16px rgba(107, 77, 255, 0.04)
	border-radius: 24px
	padding: 24px
	margin-top: 24px
	color: $primaryText

@media (min-width: 1181px)
	.faq__wrapper
		display: grid
		grid-template-columns: 2fr 1fr
		column-gap: 24px
	.card 
		height: max-content 

@media (min-width: 769px) and (max-width: 1180px)
	.faq
		margin-bottom: -24px
		padding-bottom: 24px

		&__wrapper
			margin-top: 24px

		&__title
			display: none
 
@media (max-width: 768px)
	.card
		margin-top: 16px
		padding: 16px
	.faq
		&__wrapper
			margin-top: 24px
		&__title
				display: none
		&__questions
			padding: 16px
			margin-top: 0px
			margin: 0px -16px
			border-radius: 0px
			padding-bottom: 1px

			h2
				font-size: 2.4rem
				line-height: 32px
				margin-bottom: 8px
		&__item
			padding-bottom: 8px
			margin-bottom: 8px
			&:not(:last-child)
				border-bottom: 1px solid var(--secondaryLightPurple)

		&__question
			h3
				font-size: 1.6rem
				line-height: 20px
				margin-bottom: 8px
		&__answer 
			p
				font-size: 1.4rem
		&__answer > div 
			color: var(--secondaryDarkGray)
		&__close
			display: flex
			align-items: center
		&__open
			display: flex
			align-items: center
		&__contacts
			h2
				font-size: 2.4rem
				color: $primaryText
				margin-bottom: 12px
			p
				font-size: 1.6rem
				margin-bottom: 4px

			p.faq__contact
				color: $primaryColor
				margin-bottom: 12px
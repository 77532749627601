@import ../assets/styles/_variables.sass
@import ../assets/styles/_mixin.sass
 
 
.radar 
    margin: auto 
    width: 100% 
    height: 360px

    svg
        height: 256px

    &__wrapper
        background: $primaryWhite
        box-shadow: 0px 4px 16px var(--shadowColor)
        border-radius: 24px
        padding: 24px 24px 0px
        margin: auto
        margin-bottom: 24px
        width: 100%  
             

    &__header
        border-bottom: 1px solid $secondaryLightPurple
        margin-bottom: 24px
        padding-bottom: 24px
        font-size: 2rem
        display: flex
        justify-content: space-between
        color: $secondaryDarkGray
        +r(768)
            font-size: 1.6rem

    &__link
        color: $primaryColor
        text-decoration: none
        

.tooltip
    background: $primaryWhite
    box-shadow: 0px 4px 16px var(--shadowColor)
    border-radius: 12px
    padding: 12px

    &__title
        font-size: 1.4rem
        margin-bottom: 8px
        color: $primaryColor
    &__text
        color: $secondaryGray
    &__level
        display: flex
        column-gap: 8px
        align-items: center
        font-size: 1.4rem
.level
    display: flex
    column-gap: 2px

    &__filled
        display: flex
        column-gap: 2px
        position: absolute
    &__empty
        width: 8px
        height: 16px
        border-radius: 2px
        background: $secondaryLightPurple

    &__fill
        width: 8px
        height: 16px
        border-radius: 2px
        background: $primaryColor

.tick
    color: $secondaryDarkGray
    font-size: 1.4rem
    margin: 16px


.skills
    &__item
        font-size: 1.6rem
        margin-bottom: 8px
        display: flex 
        column-gap: 8px
        +r(768)
            font-size: 1.4rem
    &__header
        display: flex  
        margin-bottom: 8px
        font-size: 1.6rem
        color: $secondaryGray

        & .skills__subject
            color: $secondaryGray   
    &__subject
        width: 50%
        text-align: left
        color: $primaryText
    &__level
        width: 50%
        display: flex
        column-gap: 8px


 

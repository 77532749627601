@import '../../assets//styles/_variables.sass'

.card
  background: $primaryWhite
  box-shadow: 0px 4px 16px $shadowColor
  border-radius: 24px
  padding: 24px
  margin-bottom: 24px
  margin-top: 24px

  &:first-child
    margin-top: 24px

  &:last-child
    margin-bottom: 0

.container
  &__user
    border-bottom: 1px solid $secondaryLightPurple
    padding-bottom: 20px
    margin-bottom: 20px

.header
  display: flex
  justify-content: space-between
  margin-bottom: 20px

  &__left
    display: flex
    align-items: center

    span
      margin-right: 16px

      &:last-child
        margin-right: 0

    img
      margin-right: 16px

.avatar
  object-fit: cover
  width: 32px
  height: 32px
  margin-right: 16px
  border-radius: 50%
  border: 1px solid black
  &__text
    font-size: 1.4rem
    line-height: 16px
    color: $primaryColor
    background-color: $secondaryLightPurple
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    margin-right: 16px

.name
  font-style: normal
  font-weight: 500
  font-size: 2rem
  line-height: 24px
  color: var(--text_color)

.status
  font-style: normal
  font-weight: 500
  font-size: 1.6rem
  line-height: 20px
  border-radius: 8px
  display: inline-flex
  align-items: center
  justify-content: center 

.score
  font-style: normal
  font-weight: 500
  font-size: 1.6rem
  line-height: 20px
  border: 8px
  color: $secondaryDarkGray

.date
  @extend .status
  color: $secondaryGray

.content
  @extend .name

.status__mobile
  display: none

@media (max-width: 768px)
  .score
    display: none

  .status:not(.status__mobile)
    display: none

  .date:not(.status__mobile)
    display: flex

  .status__mobile
    margin-bottom: 12px
    font-size: 1.4rem
    line-height: 16px    
    display: flex
    justify-content: space-between
    width: fit-content
  .status__mobile .score  
    display: block
  .name 
    font-size: 1.6rem

  .date
    font-size: 1.4rem

  .content 
    font-size: 1.4rem  
    line-height: 16px  

  .header 
    margin-bottom: 12px
@import '../assets//styles/_variables.sass';


.card_big { 
  box-shadow: 0px 4px 16px $shadowColor;
}

.card {
  width: 100%;
  display: flex;
  padding: 24px;

  background: $primaryWhite;
  box-shadow: 0px 4px 16px $shadowColor;
  border-radius: 24px;
  margin-bottom: 24px
}

.container {
  width: 100%;
}
.container p {
  font-size: 2rem !important; 
}

.description {
  font-style: normal;
  font-size: 2rem;
  line-height: 24px;
  padding: 0px 0px 16px;
}

.wrapFiles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid $secondaryLightPurple;
  margin-top: 16px;
}

.quiz__start {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &>span {
    font-style: normal;
    font-size: 2rem;
    line-height: 24px;
    color: $secondaryGray;
  }
}

@media (max-width: 768px) {
  .card {
    padding: 16px
  }

  .container p {
    font-size: 1.6rem !important; 
  }

  .card_big > .card {
    border-radius: 0px;
    padding: 20px 24px;
    margin: 0px -24px;
    width: 100vw;
    margin-bottom: 16px
  }

  .wrapFiles {
    margin-top: 12px;
    flex-direction: column;
    align-items: start;
  }
}
@import '../../assets/styles/_variables.sass'
@import '../../assets/styles/_mixin'

.modal
    &__wrapper
        width: 50%
        height: 50%
        position: absolute
        top: 100px
        left: 50%
        transform: translateX(-50%)
        box-shadow: 0px 0px 24px rgba(21, 18, 31, 0.16)
        background-color: $primaryWhite
        color: #000
        animation: open 0.5s ease-in-out forwards
        z-index: 100
        text-align: center
        +r(600)
            animation: openMobile 0.5s ease-in-out forwards
        &_closed
            animation: closed 0.5s ease-in-out forwards
            +r(600)
                animation: closedMobile 0.5s ease-in-out forwards
    &__overlay
        background-color: rgba(21, 18, 31, 0)
        position: fixed
        top: 0
        bottom: 0
        left: 0
        right: 0
        z-index: 100
        animation: start 0.5s ease forwards
        &_closed
            animation: finish 0.5s ease forwards
.root
    position: fixed
    height: 100%
    width: 100%
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    overflow: auto //TODO:
.modalRoot
    position: relative
    z-index: 999

@keyframes open
    0%
        top: -100%

    100%
        top: 100px

@keyframes closed
    0%
        top: 100px

    100%
        top: -100%
@keyframes openMobile
    0%
        top: -100%

    100%
        top: 0px

@keyframes closedMobile
    0%
        top: 0px

    100%
        top: -100%

@keyframes start
    0%
        background-color: rgba(21, 18, 31, 0)

    100%
        background-color: rgba(21, 18, 31, .4)

@keyframes finish
    0%
        background-color: rgba(21, 18, 31, .4)

    100%
        background-color: rgba(21, 18, 31, 0)

@media (max-width: 768px)
    .modal
        &__wrapper
            width: 100%
            height: 100%
            transform: none
            position: unset

@import ../assets/styles/_variables

.container
    display: flex
    align-items: center

    padding: 8px 0px

.wrapper
    background-color: $primaryWhite
    border-bottom: 1px solid $secondaryLightPurple

.list
    display: flex
    align-items: center

    &__item
        font-size: 1.6rem
        color: $secondaryGray
        margin-right: 8px
        display: flex
        align-items: center
        &::after
            content: url('../icons/arrow-right.svg')
            display: block
            position: relative
            top: 1px
            margin-left: 13px
        &:last-child
            &:after
                display: none
        a
            color: var(--text_color)
            text-decoration: none
            white-space: nowrap
            overflow: hidden

@media (min-width: 769px) and (max-width: 1180px)
    .container
        padding: 8px 48px
    .wrapper
        border-bottom: none
        display: none

@media (max-width: 768px)
    .wrapper
        border-bottom: none
        display: none

    .container
        justify-content: center
        padding-top: 24px

    .list
        flex-direction: column

        &__item
            margin-bottom: 40px
            margin-right: 0px

            &::after
                top: 27px
                left: -50%
                margin-left: 0px
                transform: rotate(90deg)
li.list__item.ellipsis
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    display: inline-block
    a
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
.loader
    position: relative
    margin-left: 155px
    height: 37px

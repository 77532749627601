@import '../assets/styles/_variables.sass'

.notifications
	&__title
		font-size: 3.2rem
		margin-top: 24px
		color: $primaryText
		
	&__preloader
		text-align: center 
		width: 100%
		margin-top: -20px
		& > img
			width: 100px
			height: 100px
			margin: auto

.overlay
	width: 100%
	height: 100%
	top: 0
	left: 0
	background: transparent
	z-index: 3
	position: fixed

.notificationsButton
	position: relative

	button
		background: transparent
		border: none
		padding: 0
		cursor: pointer

	&__unread
		height: 8px
		width: 8px
		background: $tertiaryRed
		position: absolute
		top: 0
		left: 15px
		border-radius: 10px

.notificationsPopup
	position: absolute
	width: 400px
	padding: 24px 32px
	background: $primaryWhite
	box-shadow: $strongShadow
	border-radius: 24px
	z-index: 4
	left: 100%
	transform: translateX(-50%)
	&__header
		display: none

	&__link
			margin-bottom: 32px
			text-align: right

			a
					font-size: 16px
					color: $primaryColor
					text-decoration: none

	&__noItems
		font-size: 16px
		line-height: 20px
		color: $secondaryGray
		text-align: center


.notification
	margin-bottom: 32px
	background: $primaryWhite
	box-shadow: 0px 4px 16px $shadowColor
	border-radius: 24px
	padding: 24px
	font-size: 20px
	line-height: 24px
	position: relative

	&:after
		content: ""
		display: block
		position: absolute
		width: 4px
		height: 16px
		border-radius: 2px
		background: $secondaryLightPurple
		bottom: -24px

	&:last-child
		margin-bottom: 40px

		&:after
			display: none

	&__date
		padding: 2px 8px
		margin-bottom: 36px
		font-size: 16px
		line-height: 20px
		color: $primaryColor
		border-radius: 8px
		background: $secondaryLightPurple
		display: inline-block
		margin-left: 12px
		position: relative

		&:after
			content: ""
			display: block
			position: absolute
			width: 4px
			height: 24px
			border-radius: 2px
			background: $secondaryLightPurple
			bottom: -28px

	&__top
		border-bottom: 1px solid $secondaryLightPurple
		padding-bottom: 18px
		margin-bottom: 16px
		display: flex
		justify-content: space-between

	&__bottom
		display: flex
		justify-content: space-between
		column-gap: 8px

	&__link
		flex-shrink: 0
		a, span
			color: $primaryColor
			text-decoration: none
			font-weight: 600
			cursor: pointer

	&__type
		display: flex
		align-items: center
		text-transform: capitalize
		color: $secondaryDarkGray

		svg
			margin-right: 8px

	&__time
		display: flex
		align-items: center
		color: $secondaryGray

		svg
			margin-right: 10px

	&__icon
		height: 24px
	
		&_green
			svg
				path 
					fill: $tertiaryGreen

		&_red
			svg
				path 
					fill: $tertiaryRed
	&__text
		color: $primaryText
		word-break: break-word
		overflow-wrap: break-word

.notificationPopup
	margin-bottom: 32px
	font-size: 16px
	line-height: 20px
	display: flex
	align-items: center
	color: $primaryText

	svg	
		width: 20px
		height: 20px


	&__icon
		margin-right: 18px

		&_green
			svg
				path 
					fill: $tertiaryGreen

		&_red
			svg
				path 
					fill: $tertiaryRed

	&__body
		margin-left: 18px
 

	&__bottom
		display: flex
		margin-top: 8px
		align-items: center

	&__viewed
		height: 8px
		width: 8px
		background: $primaryColor
		border-radius: 10px
		margin-right: 8px

	&__time
		color:$secondaryDarkGray

	&__text 
		display: block
		word-break: break-word
		overflow-wrap: break-word
		
		a 
			text-decoration: none
			color: unset
		span
			cursor: pointer
		 
.noItems
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	color: $secondaryDarkGray

	h3
		margin: 40px 0px 16px 0px
		font-weight: 700
		font-size: 32px
		line-height: 40px

	p
		font-size: 20px
		line-height: 24px

@media (min-width: 769px) and (max-width: 1180px)
	.notifications
		padding-bottom: 24px
		margin-bottom: -24px
		&__title
				display: none
	.notificationsPopup > div:first-child
		display: none


@media (max-width: 768px)
	.notifications
		&__title
				display: none
	.notificationsPopup
		top: 0px
		bottom: unset
		left: unset
		right: 0px
		transform: none
		height: calc(100vh)
		background: $secondaryBackground
		padding: 0px 24px
		display: flex
		flex-direction: column
		padding-bottom: 24px
		position: relative
		width: 100vw
		margin: 0px -24px
		z-index: 6 
		padding-right: 16px
		height: auto
		margin-top: auto
		bottom: 0
		top: unset
		position: relative
		left: 24px
		padding-left: 16px
		padding-bottom: 0px

		&__header
			display: flex
			justify-content: space-between
			margin-bottom: 16px
			padding: 16px
			font-size: 1.6rem
			border-bottom: 1px solid $secondaryLightPurple

		& > div:first-child
				margin-bottom: 20px

		&__link
			border-bottom: 1px solid $secondaryLightPurple
			padding-bottom: 12px
			margin-bottom: 12px

	.notificationPopup
		margin-bottom: 0px

 
		&:not(:last-child)
			border-bottom: 1px solid $secondaryLightPurple
			margin-bottom: 12px

		&__body
			margin-left: 12px
			line-height: 14px
			font-size: 1.2rem

		&__bottom
			margin-top: 4px	
			margin-bottom: 12px

		&__time
			margin-bottom: 0px
			
 
	.overlay
		display: none


	.notification
		padding: 16px 16px 0px

		&__top
			font-size: 1.6rem
			margin-bottom: 12px
			padding-bottom: 12px

			svg
				width: 20px
				height: 20px

		&__bottom
			flex-direction: column

		&__text
			margin-bottom: 12px
			font-size: 1.4rem

		&__link
			text-align: left
			margin-bottom: 12px
			font-size: 1.6rem

		&__type > div
			display: flex
			align-items: center

		&__date
			font-size: 1.4rem
@import ../assets/styles/_variables
@import ../assets/styles/_styles
@import ../assets/styles/_mixin

.knowbase
    margin-top: 30px

    &__title
        font-size: 30px
        color: $primaryText

    &__header
        margin-bottom: 30px
        display: flex
        justify-content: space-between

    &__search
        width: 360px

    &__structure
        display: grid
        grid-template-columns: 70% 30%
        gap: 16px
        align-items: center
        margin-bottom: 24px
        @include card

        & > div
            display: flex
            gap: 16px

        a, span
            text-decoration: none
            color: $primaryText
            @include font20
            display: flex

.structure
    &__header
        // margin-top: 24px
        padding-top: 24px
    &__title
        font-size: 3.2rem
        color: $primaryText
.documents
    @include card
    display: grid
    gap: 16px

    &__row
        color: $primaryText
        display: grid
        grid-template-columns: 3fr 1fr 1fr
        gap: 16px
        @include font20

        &:last-child
            margin-bottom: 0px

        svg
            margin-right: 16px
            width: 24px
            height: 24px
            flex-basis: 100%
            max-width: 24px

            path
                fill: $primaryText

    &__right

    &__head
        color: $secondaryGray
        @include font20

    &__folder
        display: flex
        align-items: center
        cursor: pointer

    &__file
        display: flex
        align-items: center
        padding-left: 64px

        a
            text-decoration: none
            color: $primaryText

        span
            color: $secondaryGray
    &__search
        padding-left: 0px

    &__arrow
        display: flex
        align-items: center

        svg
            width: 16px
            height: 16px
            margin-right: 16px

            path
                fill: $secondaryDarkGray

.document__isCurrent a
    color: $primaryColor

.document__isCurrent
    svg
        path
            fill: $primaryColor !important

.related
    &__title
        margin-bottom: 16px
        font-size: 2rem
        color: $secondaryDarkGray

    &__list
        display: grid
        gap: 24px
        grid-template-columns: repeat(3,minmax(233px,1fr))

    &__item:not(:last-child)
        margin-right: 24px
    &__item
        @include card
        display: flex
        font-size: 14px
        width: 100%
        border-radius: 16px
        &>a,&>span
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

        svg
            flex-shrink: 0
            width: 14px
            height: 14px
            margin-right: 8px

        a
            text-decoration: none
            color: $primaryText

        span
            color: $secondaryGray

.container
    overflow-x: auto
    padding: 20px
    width: 100%
    margin-bottom: 24px
    min-height: calc(100vh - 186px)

.first_node
    margin-top: -76px !important

.first_node:not(:has(li > ul))
    justify-content: center

.first_node > li
    margin: auto

.tree
    height: auto
    text-align: center

    &>ul>li::before
        display: none
    & ul
        padding-top: 40px
        position: relative
        transition: 0.5s
        display: flex
        text-align: center
    & li
        display: inline-table
        text-align: center
        list-style-type: none
        position: relative
        padding: 40px 24px
        transition: 0.5s

.tree li::before,
.tree li::after
    content: ""
    position: absolute
    top: 0
    right: 50%
    border-top: 1px solid $secondaryLightPurple
    width: 51%
    height: 40px

.tree li::after
    right: auto
    left: 50%
    border-left: 1px solid $secondaryLightPurple

.tree li:only-child::after,
.tree li:only-child::before
    // display: none

.tree li:only-child
    padding-top: 40px
    +r(768)
        padding-top: 120px

.tree li:first-child::before,
.tree li:last-child::after
    border: 0 none

.tree li:last-child::before
    border-right: 1px solid $secondaryLightPurple
    border-radius: 0 12px 0 0
    -webkit-border-radius: 0 12px 0 0
    -moz-border-radius: 0 12px 0 0

.tree li:first-child::after
    border-radius: 12px 0 0 0
    -webkit-border-radius: 12px 0 0 0
    -moz-border-radius: 12px 0 0 0

.tree ul ul::before
    content: ""
    position: absolute
    top: 0
    left: 50%
    border-left: 1px solid $secondaryLightPurple
    width: 0
    height: 40px

.tree li a:hover,
.tree li a:hover i,
.tree li a:hover span,
.tree li a:hover + ul li a
    color: #000
    // border: 1px solid #94a0b4

.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before
    border-color: $primaryColor40percent

.card
    width: 264px
    background: $primaryWhite
    box-shadow: $shadow
    border-radius: 16px
    min-height: 160px
    display: inline-flex
    align-items: center
    justify-content: center
    transition: 0.5s
    position: relative
    padding: 24px 24px 16px
    word-break: break-word
    text-decoration: none
    color: initial

.card:hover
    box-shadow: $strongShadow

.eye
    border: none
    min-width: 40px
    height: 40px
    background: $primaryWhite
    box-shadow: $shadow
    border-radius: 12px
    position: absolute
    top: calc(100% + 20px)
    z-index: 1
    cursor: pointer

    &:before
        content: ""
        position: absolute
        top: -30px
        right: 71%
        border-top: 1px solid var(--secondaryLightPurple)
        width: 51%
        height: 40px
        transform: rotate(90deg)

.tree li a:hover .eye:before
    border-color: $primaryColor40percent

.department
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    text-align: left
    align-self: stretch
    color: $primaryText

    &__name
        font-size: 2rem
        padding-bottom: 16px
        border-bottom: 1px solid $secondaryLightPurple
        margin-bottom: 16px

    &__people
        font-size: 1.6rem
        margin-top: 16px

    &__manager
        padding-bottom: 16px
        border-bottom: 1px solid $secondaryLightPurple
        display: flex
        align-items: center

.manager

    &__avatar

    &__name
        font-size: 1.6rem
        margin-bottom: 4px

    &__position
        font-size: 1.2rem
        color: $secondaryGray

.tabLine_name
    display: flex
    justify-content: left
    align-items: center

    font-style: normal
    font-weight: 500
    font-size: 2rem
    line-height: 24px
    color: var(--text_color)

.tabLine_name_round
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center

    width: 40px
    height: 40px
    margin-right: 16px

    background: $secondaryLightPurple
    border-radius: 26px

    font-style: normal
    font-weight: 600
    font-size: 1.6rem
    line-height: 40px
    text-align: center
    color: $primaryColor

.tabLine_name_round img
    border-radius: 26px
    overflow: hidden
    width: 100%
    height: 100%
    object-fit: cover

.NoItem
    font-style: normal
    font-weight: 500
    font-size: 2rem
    line-height: 24px
    color: $secondaryGray
    margin-bottom: 20px

.noItems
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    color: $secondaryDarkGray
    padding: 24px 0px
    min-height: calc(100vh - 186px)

    &>h3
        margin: 40px 0px 16px 0px
        font-weight: 700
        font-size: 32px
        line-height: 40px

    p
        font-size: 20px
        line-height: 24px

.input
    height: 100%
    font-size: 2rem
    line-height: 24px
    color: $primaryText
    border: 0px
    background: $primaryWhite
    &__search
        background-color: $primaryWhite
        border: 2px solid $secondaryLightPurple
        border-radius: 12px
        display: flex
        align-items: center
        height: 44px
        padding: 0 17px
        svg
            margin-right: 10px
        &:focus-within
            border-color: $primaryColor
        &:hover
            border-color: $primaryColor
            cursor: pointer

.input::placeholder
    color: $secondaryGray

.structure__mobileHeader
    display: none

.structureInKnowbase
    margin-top: -30px

    & .structure__header
        width: 100%
div.search_base
    +r(768)
        width: 100%
        &>button[type="button"]
            width: 100%
            gap: 16px
            padding: 0 16px
            justify-content: flex-start
            &>span
                display: inline

@media (min-width: 769px) and (max-width: 1180px)
    .knowbase
        &__search
            width: 100%
        &__title
            display: none

        &__structure

            a, span
                font-size: 1.6rem
                line-height: 18px
                display: flex
                align-items: center

    .structure
        &__header
            display: none
    .documents
        &__row
            font-size: 1.6rem

    .document
        margin-bottom: -24px
        padding: 24px 0
        margin-top: 0
        &__body
            flex-direction: column

        &__left
            width: 100%
        &__right
            width: 100%

        &__knowbase, &__onpage, &__data
            margin: 0px 0px

            margin-bottom: 24px

        &__knowbase
            padding: 24px
            padding-bottom: 0px
            & .document__subtitle
                border-bottom: none
        &__onpage
            margin-bottom: 0px
            padding: 16px
            padding-bottom: 0px

            & .document__subtitle
                border-bottom: none
        &__documents
            padding-bottom: 16px
            padding-top: 16px
            border-top: 1px solid $secondaryLightPurple

        &__articles
            padding-top: 12px
            margin-top: 12px
            border-top: 1px solid $secondaryLightPurple
            padding-bottom: 16px
            margin-top: -16px
        .related
            &__title
                margin-bottom: 16px

@media (max-width: 768px)
    .structure
        &__header
            display: none

    .structureInKnowbase .structure__mobileHeader
        display: none
    .input
        font-size: 1.6rem
    .knowbase
        margin: 16px -16px 16px
        &__header
            margin-bottom: 16px
        &__search
            width: 100%
            padding: 0px 16px
            font-size: 12px
        &__input
            font-size: 1.6rem
        &__title
            display: none
        &__structure
            border-radius: 0px
            padding: 16px
            margin-bottom: 16px

            a
                font-size: 1.4rem
                line-height: 16px

            & > span
                display: none
            & > div
                width: 100%
                align-items: center

                svg
                    width: 20px
                    height: 20px
    .documents
        border-radius: 0px
        padding: 16px
        overflow: scroll
        width: max-content

        &__wrapper
            width: 100%
            overflow-x: scroll

        &__right
            width: 40%

        &__left
            width: 100%
        &__row
            font-size: 1.4rem
            line-height: 16px
            margin-bottom: 12px
            grid-template-columns: 1fr 1fr 1fr

            svg
                margin-right: 8px
                max-width: 24px
                width: 16px
                height: 16px
        &__head
            font-size: 1.4rem
            line-height: 20px
            margin-bottom: 12px
        &__arrow
            svg
                margin-right: 12px
                width: 12px
                height: 6px
                margin-left: 6px
    .document
        margin-top: 20px

        &__body
            flex-direction: column

        &__left
            width: 100%
            margin-bottom: 16px
        &__right
            width: 100%
        &__title
            font-size: 2.4rem
            line-height: 32px
        &__header
            margin-bottom: 16px
        &__desc
            font-size: 1.4rem
            line-height: 16px
            column-gap: 16px
        &__knowbase, &__onpage, &__data
            margin: 0px -24px
            border-radius: 0px
            margin-bottom: 16px

        &__knowbase
            padding: 16px
            & .document__subtitle
                border-bottom: none
        &__onpage
            margin-bottom: 0px
            padding: 16px
            padding-bottom: 0px

            & .document__subtitle
                border-bottom: none
        &__documents
            padding-bottom: 16px
            padding-top: 16px
            margin-top: 0px
            border-top: none
        &__subtitle
            &>h3
                font-size: 1.4rem
            svg
                width: 20px
                height: 20px
                margin-right: 8px
        &__data
            &>h3
                font-size: 2rem
                line-height: 24px
                margin-bottom: 16px
                font-weight: 600
            p
                font-size: 1.4rem
                line-height: 18px
                margin-bottom: 12px
        &__articles
            padding-top: 12px
            margin-top: 12px
            border-top: 1px solid $secondaryLightPurple
            padding-bottom: 16px
            margin-top: -16px

    .related
        &__title
            font-size: 1.6rem
            margin-bottom: 8px
        &__list
            grid-template-columns: 1fr
            gap: 12px
        &__item
            width: 100%
            border-radius: 12px
            padding: 12px
            font-size: 1.4rem

    .structure__mobileHeader
        display: block
        padding: 0px 24px
    .container
        margin-bottom: 0px

    .card
        padding: 16px
    .department
        &__name
            font-size: 1.6rem
            line-height: 20px
            margin-bottom: 12px
            padding-bottom: 12px
        &__people
            margin-top: 12px
            font-size: 1.4rem

    .manager
        padding-bottom: 12px
        &__name
            font-size: 1.4rem
            line-height: 16px
    .tabLine_name_round
        width: 32px
        height: 32px
        font-size: 1.4rem
        margin-right: 8px

    .eye
        width: 36px
        height: 36px

        svg
            width: 20px
            height: 20px

    .noItems
        svg
            width: 260px
            height: 214px
        &>h3
            font-size: 2.4rem
            line-height: 32px
        p
            font-size: 1.6rem
            line-height: 20px

.folder
    &__name
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        cursor: pointer
        &_tooltip
            font-size: 1.2rem
            line-height: 1.4rem
.documents__left
    overflow: hidden
.document__rootFolder
    display: none
.document__rootFile
    padding-left: 0

@import ../../assets/styles/_variables

.iconButton
	background: transparent
	border: none
	padding: 0
	cursor: pointer

	&__text
		display: none

	svg
		width: 24px
		height: 24px

		path 
			fill: $secondaryGray

	&:hover
		svg
			path 
				fill: $secondaryDarkGray

@media (max-width: 768px)
	.iconButton
		display: flex
		align-items: center

		&__text
			display: block
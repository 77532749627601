@import ../../assets/styles/_variables

.wrapper
    width: 100%
    text-align: left
    margin-bottom: 24px
    label.label
        font-size: 1.6rem
        line-height: 20px
        color: $secondaryDarkGray
        margin-bottom: 4px
        padding: 0

.container
    border: 2px solid $secondaryLightPurple
    padding: 0 16px
    margin: 0 auto
    width: 100%
    box-sizing: border-box
    border-radius: 12px
    // margin-bottom: 8px
    position: absolute
    top: 0
    left: 0
    z-index: 1
    background-color: $primaryWhite
    &__open
        z-index: 3

    input.input
        width: 100%
        outline: none
        padding: 10px 0px
        background-color: $primaryWhite
        box-sizing: border-box
        font-size: 2rem
        color: $primaryText
        border: none
        &::placeholder
            color: $secondaryGray

        &_open
            border-bottom: 1px solid $secondaryLightPurple
    .multiple
        width: 100%
        outline: none
        padding: 10px 0px
        background-color: $primaryWhite
        box-sizing: border-box
        font-size: 2rem
        color: $primaryText
        height: 44px
        &>div
            width: calc( 100% - 24px )
            white-space: nowrap
            text-overflow: ellipsis

        &_open
            border-bottom: 1px solid $secondaryLightPurple

    &:focus-within
        border: 2px solid $primaryColor

    &.error
        border: 2px solid red

    &:hover
        border: 2px solid $primaryColor

.position
    width: 100%
    position: relative
    height: 48px
    margin-bottom: 5px
    // margin-bottom: 24px TODO:

ul.popower, div.popower
    padding: 12px 0
    max-height: 168px
    overflow: auto
    &::-webkit-scrollbar
        width: 8px
        background: inherit

    &::-webkit-scrollbar-track
        background: none

    &::-webkit-scrollbar-track-piece
        background: none

    &::-webkit-scrollbar-thumb
        background: $secondaryLightPurple
        border-radius: 5px
        width: 8px
.option
    line-height: 24px
    font-size: 2rem
    color: $primaryText
    margin-bottom: 8px
    &:hover
        cursor: pointer
        color: $primaryColor
        background-color: $secondaryBackground
    &_empty
        color: $secondaryGray
    &:last-child
        margin-bottom: 0
    &[data-active="1"]
        color: $primaryColor
        background-color: $secondaryBackground

.arrow
    width: 16px
    height: 16px
    // border: 2px solid $secondaryLightPurple
    // border-left: none
    // border-top: none
    // transform: rotate(45deg)
    transition: transform .22s
    position: absolute
    top: 16px
    right: 16px
    &>path
        fill: $primaryColor
    &_active
        transform: rotate(180deg)
        transition: transform .22s

.message
    color: $tertiaryRed
    display: block
    font-size: 1rem
    line-height: 1rem
    position: relative
    left: 0
    min-height: 1rem
    width: calc(100% - 24px)
    max-width: 398px //TODO:
    font-size: 1.4rem

@media (max-width: 768px)
    .container input.input
        font-size: 1.6rem
        padding: 8px 0px
    .wrapper label.label
        font-size: 1.4rem
    .arrow
        top: 10px
    .option
        font-size: 1.6rem

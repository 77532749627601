@import '../../assets/styles/_variables.sass';

.wrapper {
  padding: 24px;
  background-color: $primaryWhite;
  box-shadow: 0px 4px 16px $shadowColor;
  border-radius: 24px;
  width: 456px;
}

.header {
  font-size: 2rem;
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid $secondaryLightPurple;
  color: $secondaryDarkGray;

  svg {
    margin-right: 10px;
  }
}

.submit {
  font-size: 2rem;
  line-height: 24px;
  color: $primaryWhite;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
    transition: transform 0.15s;
  }

}

.buttons {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 24px;
}

.password_wrap {
  position: relative;
  width: 100%;

  .recover {
    position: absolute;
    top: -1px;
    right: 0;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 20px;

    color: $primaryColor;
    cursor: pointer;
    text-decoration: none;
  }
}


      
@media (min-width: 769px) and (max-width: 1180px) {
    .wrapper {
       margin: auto;
    } 
}  


@media (max-width: 768px) {
    .wrapper {
      width: 100%;
    } 
    .header {
      font-size: 1.6rem;
      color: $secondaryDarkGray;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
    .password_wrap {

      .recover {  
      font-size: 1.4rem;
    }
    }

    .buttons {
      margin-top: 16px
    }

    .submit {
      font-size: 1.6rem;
    }
}
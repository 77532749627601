@import ../../assets/styles/_variables
@import ../../assets/styles/_mixin

.circle
    border-radius: 50%
    background: $secondaryLightPurple
    position: relative
    overflow: hidden
.square
    border-radius: 8px
    background: $secondaryLightPurple
    position: relative
    overflow: hidden
.line
    border-radius: 8px
    height: 16px
    background: $secondaryLightPurple
    position: relative
    overflow: hidden
    &__head
        background: $secondaryBackground
.border
    border-radius: 8px
    height: 1px
    background: $secondaryLightPurple
    position: relative
    overflow: hidden
.rect
    border-radius: 12px
    background: $secondaryLightPurple
    position: relative 
    overflow: hidden
.card
    border-radius: 24px
    background: $primaryWhite
    padding: 12px
    overflow: hidden

.circle::after, .line::after, .rect::after
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    transform: translateX(-100%)
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0))
    animation: shimmer 2s infinite
    content: ''

.breadcrumbs 
    background: $primaryWhite
    border-bottom: 1px solid $secondaryLightPurple
    position: absolute
    left: 0
    top: 0px
    padding: 10px 0px 10px 155px
    margin: 0px -24px 24px -155px
    width: calc(100% + 312px)
    &__inner

    +r(1180)

    +r(768)
        height: 37px
        display: flex
        gap: 8px
        border-bottom: none
        background: transparent
        padding-left: 16px
        margin-bottom: 8px

.list > div
    display: flex
    row-gap: 32px
    flex-direction: column
    padding: 24px

.control
    display: block

    &__tabs
        margin-bottom: 24px
        display: flex
        justify-content: space-between
        column-gap: 16px
    &__buttons
        display: flex
        column-gap: 16px

.light
    background-color: $secondaryBackground

@keyframes shimmer
    100%
        transform: translateX(100%)
@import ../../assets/styles/_variables

.wrapper
    background: $secondaryBackground
    display: flex
    flex-direction: column
    min-height: 100vh
.footer
    margin-top: auto
.app
    min-height: calc(100vh - 186px)
    margin-bottom: 24px

@import ../../assets/styles/_variables

.wrapper
    width: 100%
    position: relative
.label
    color: $secondaryDarkGray
    font-size: 1.6rem
    margin-bottom: 4px
.input
    border-radius: 12px
    border: 2px solid $secondaryLightPurple
    font-size: 2rem
    line-height: 24px
    color: $primaryText
    height: 48px
    padding-left: 16px
    padding-right: 48px
    background: $primaryWhite
    &__disabled
        background-color: $secondaryBackground
        &:focus
            background-color: $secondaryBackground
    &:hover
        cursor: pointer
        border: 2px solid $primaryColor
    &:focus
        border: 2px solid $primaryColor
    &:active
        border: 2px solid $primaryColor
    &::placeholder
        color: $secondaryGray
    &__wrapper
        position: relative

        &>svg
            min-width: 24px
            height: 24px
            position: absolute
            right: 16px
            top: 50%
            transform: translateY(-50%)
            cursor: pointer
            &>path
                fill: $secondaryDarkGray
.calendar
    position: absolute
    top: calc( 100% + 10px )
    min-width: 328px
    z-index: 10
svg.removeIcon
    width: 24px
    height: 24px
    cursor: pointer

.input
    width: 100%

.input__wrapper
    svg
        width: 14px
        height: 14px
@import ../assets/styles/_variables

.overlay
	width: 100%
	height: 100%
	top: 0
	left: 0
	background: transparent
	z-index: 3
	position: fixed

.videoContainer
	position: relative

	input[type="range"]
		width: 100%
		-webkit-appearance: none
		cursor: pointer
		height: 4px
		background: transparent
		overflow: hidden
		margin: 0px

		&::-webkit-slider-runnable-track
			-webkit-appearance: none
			height: 4px
			background: $primaryWhite
			opacity: 0.16
			border-radius: 2px

		&::-webkit-slider-thumb 
			-webkit-appearance: none
			background: $primaryWhite
			height: 8px
			width: 8px
			border-radius: 2px
			box-shadow: -9999px 0 0 9999px $primaryWhite
			opacity: 1

.videoСontrols
	position: absolute
	bottom: 0px
	width: 100%
	padding: 0px 16px
	background: rgba($primaryBlack, .2)
	padding-top: 50px
	padding-bottom: 14px
	border-radius: 0 0 12px 12px

	&__buttons
		position: relative
		margin-bottom: 14px

	&__left
		display: flex
		position: absolute
		bottom: 0px

	&__center
		position: absolute
		bottom: 0px
		left: 50%
		transform: translate(-50%, 0)

	&__right
		display: flex
		position: absolute
		bottom: 0px
		right: 0px

	&__volumeWrapper
		display: flex

	&__volume
		cursor: pointer

	&__volumeBar
		display: flex
		align-items: center
		position: relative
		margin-left: 16px

	&__play
		cursor: pointer

	&__settings
		margin-right: 26px
		cursor: pointer

	&__fullscreen
		cursor: pointer

	&__bar
		position: relative

	&__settingsPanel
		background: $primaryWhite
		border-radius: 12px
		padding: 17px
		position: absolute
		right: 0px
		bottom: 0px
		right: 50px
		bottom: 36px
		min-width: 200px
		z-index: 4

	&__playbackPanel
		background: $primaryWhite
		border-radius: 12px
		padding: 17px
		position: absolute
		right: 0px
		bottom: 0px
		right: 63px
		bottom: 44px
		width: 200px
		z-index: 4

	&__settingsItem
		display: flex
		margin-bottom: 12px
		align-items: center
		font-size: 16px

.videoControls__time
		display: flex
		align-items: center
		font-size: 16px
		color: $primaryWhite
		margin-left: 16px

.videoControls__settingsItem
	cursor: pointer
	display: flex
	align-items: center
	font-size: 16px

	&:not(:last-child)
		margin-bottom: 12px

	svg
		margin-right: 9px

.videoСontrols__playbackList
	li
		margin-bottom: 12px
		font-size: 16px
		display: flex 
		align-items: center
		cursor: pointer
		line-height: 20px

		&:not(:last-child)
			margin-bottom: 12px

		&.videoСontrols__currentPlayback
			svg
				visibility: visible

		svg
			margin-right: 12px
			visibility: hidden

.videoСontrols__playbackTitle
	line-height: 20px
	cursor: pointer
	padding-bottom: 12px
	border-bottom: 1px solid $secondaryLightPurple
	margin-bottom: 12px
	font-size: 16px
	display: flex
	align-items: center

	svg
		margin-right: 12px

.fullScreen
	position: fixed
	top: 0
	left: 0
	border-radius: 0px
	width: 100%
	height: 100vh
	z-index: 10
	display: flex
	background: black
	object-fit: cover

	video
		border-radius: 0px
		height: 100%
		margin: auto
		width: auto

.inPicture
	position: fixed
	z-index: 2
	width: 600px
	bottom: 10px
	left: 10px

.videoСontrols__playLine
	width: 100%
	height: 4px
	background: $primaryWhite
	border-radius: 2px
	position: absolute
	pointer-events: none

.videoСontrols__progressBar
	bottom: 2px

@media (max-width: 768px)
	.videoContainer
		margin: 0px -24px
		margin-top: -24px

	.videoСontrols
		border-radius: 0px

	.fullScreen
		margin: 0px

		video
			width: 100vw
@import '../assets/styles/_variables.sass'

.actionButton
  padding: 10px 22px
  background: $primaryColor
  border-radius: 12px
  color: $primaryWhite
  text-decoration: none
  font-size: 2rem
  line-height: 24px
  border: 2px solid transparent

  &:hover
    background: $primaryHoverColor
 

@media (max-width: 768px)
  .actionButton
    font-size: 1.6rem
    padding: 8px 16px
    line-height: 16px
@import '../../assets//styles/_variables.sass'

ul.progress
  display: flex 
  list-style: none
  margin-bottom: 8px
  box-sizing: border-box

li.progress__item
  width: 20px
  height: 8px
  margin-right: 8px
  border: 2px solid $secondaryLightPurple
  border-radius: 4px
  background: $secondaryLightPurple
  width: 100%
  cursor: pointer

  &:last-child
    margin-right: 0

  &_current
    background: $primaryColor
    box-shadow: 0px 3px 6px $shadowColor
    border-color: $primaryColor
    box-shadow: 0px 2px 6px var(--primary_color)
    
  &_previous
    background: $primaryColor
    border-color: $primaryColor
.wrapper
  display: block
  width: 100%

.question
  font-size: 2rem
  line-height: 24px
  padding: 8px 0 0 
  margin-bottom: 8px
  width: 100%
  color: var(--text_color)

  &__caption
    display: flex
    column-gap: 24px
    align-items: center
    padding: 16px 0 0

    svg
      circle
        fill: $secondaryDarkGray 
  &__type
    display: flex
    align-items: center
    column-gap: 8px
    color: $primaryText
    font-size: 1.4rem
    line-height: 16px 
  &__desc
    font-size: 1.6rem
    line-height: 24px
    padding: 0 0 16px
    border-bottom: 1px solid $secondaryLightPurple
    margin-bottom: 16px
    width: 100%
    color: $secondaryDarkGray
  &__required
    color: $primaryText
    font-size: 1.4rem
    line-height: 16px  

ul.answer__wrapper
  padding-top: 8px
  width: 100%
  border-bottom: 1px solid $secondaryLightPurple
  margin-bottom: 16px

  & > li
    margin-bottom: 16px
    display: flex

    input[type="radio"], input[type="checkbox"]
      display: none

      &:checked + label.radio
        &:before
          content: ''
          display: block
          background-color: $primaryColor
          width: 12px
          height: 12px

          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
          border-radius: 50%

      &:checked + label.checkbox
        background-color: $primaryColor
        border: 2px solid $primaryColor

.checkbox
  position: relative
  width: 24px
  height: 24px
  border: 2px solid $secondaryGray
  border-radius: 8px
  margin-right: 8px
  display: flex
  align-items: center
  justify-content: center

.radio
  position: relative
  display: block
  width: 24px
  height: 24px
  border: 2px solid $secondaryGray
  border-radius: 50%
  margin-right: 8px

  &__content
    font-style: normal
    font-weight: 500
    font-size: 1.6rem
    line-height: 24px
    color: var(--text_color)

.button
  &__group
    display: flex
    align-items: center

    justify-content: flex-end

    & > button
      margin-right: 16px

      &:last-child
        margin-right: 0
      &.button__prev
        margin-right: auto
button.button
  text-transform: initial

div.modal
  width: 400px
  background: $primaryWhite
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12)
  border-radius: 24px
  padding: 24px
  height: initial

  &__header
    display: flex
    align-items: center
    padding-bottom: 24px
    justify-content: space-between
    border-bottom: 1px solid $secondaryLightPurple
    &>span
      color: $secondaryDarkGray
      font-size: 2rem
      line-height: 24px
    .cancel
      width: 24px
      height: 24px

      svg
        width: 24px
        height: 24px
        path
          fill: $primaryBlack
.score
  display: flex
  justify-content: center
  margin-top: 24px
.modal__content
  color: var(--text_color)
  font-size: 2rem
  line-height: 24px
  padding: 24px 0

.modal__buttons
  display: flex
  align-items: center
  justify-content: flex-end

  button
    font-size: 2rem
    line-height: 24px
    border: 1px solid transparent
    text-transform: capitalize
    background-color: transparent
    &:hover
      cursor: pointer
    &:first-child
      color: $primaryColor
      margin-right: 24px

    &:last-child
      color: $primaryWhite
      height: 48px
      background: #FF4E6B
      border-radius: 12px

  a.link
    font-size: 2rem
    line-height: 24px
    border: 1px solid transparent
    text-decoration: none
    background-color: transparent
    display: inline-flex
    align-items: center

    &__course
      color: $primaryColor
      margin-right: 24px

    &__next
      color: $primaryWhite
      padding: 0 24px
      height: 48px
      background: $primaryColor
      border-radius: 12px

    &:last-child
      margin-right: 0

.textarea
  width: 100%
  outline: none
  padding: 10px 14px
  border: 2px solid $secondaryLightPurple
  border-radius: 12px
  background-color: $primaryWhite
  box-sizing: border-box
  margin-bottom: 4px
  font-size: 2rem
  color: $primaryText
  height: 196px
  margin-bottom: 36px
  &:active
      background: transparent 
      outline: none
  &:focus
      background: transparent
      outline: none

  &:hover
      border: 2px solid $primaryColor

@media (max-width: 768px)
    .button
      &__group
        flex-direction: column
        gap: 8px

        & > button
          margin-right: 0px
          width: 100% 
    .modal__buttons
      button
        width: auto
        font-size: 1.6rem
        height: auto
        padding: 8px 16px
      button:last-child
        width: auto

    li.progress__item
      margin-bottom: 12px
      width: 14px
      height: 6px
      width: 100%

    div.modal
      width: 100vw
      height: auto
      border-radius: 24px 24px 0px 0px
      margin-top: auto

      &__buttons
        margin-top: auto

      &__header > span
        font-size: 1.6rem

    .modal__content
      font-size: 1.4rem
      text-align: left
      color: $secondaryDarkGray

    .modal > div 
      display: flex
      flex-direction: column
      height: 100%

    .modal__buttons a.link
      font-size: 1.6rem

    .arrow
      position: relative
      border: 1.5px solid $primaryColor
      border-radius: 10px
      width: 36px
      height: 36px
      display: flex
      align-items: center
      justify-content: center

      svg 
        path 
          fill: $primaryColor

      &__disabled
        pointer-events: none
        background: $secondaryGray
        border-color: $secondaryGray

        svg
          path
            fill: $secondaryDarkGray

    .arrow > button.button[type="submit"]
      width: 100%
      border-radius: 0px
      background: none
      height: 100%
      z-index: 2
      position: absolute


    .arrow:first-child
      margin-right: 16px

    .question
      font-size: 1.4rem
      padding-bottom: 12px
      padding-top: 0px
    .radio
      &__content
        font-size: 1.4rem

    ul.progress
      margin-bottom: 0px
    
    .radio 
      width: 20px
      height: 20px

    ul.answer__wrapper > li
      margin-bottom: 14px

@import '../assets//styles/_variables.sass'

.finish
	display: flex
	align-items: center
	height: 100vh
	background: $primaryColor


	button.button__white
		background: $primaryWhite
		color: $primaryColor

	button.button__outlined
		background: $primaryColor
		color: $primaryWhite
		border: 2px solid $primaryWhite

	button:not(last-child)
		margin-right: 16px

	h3
		font-size: 32px
		color: $primaryWhite
		margin-bottom: 16px

	p
		font-size: 20px
		color: $primaryWhite


.finishReview
	justify-content: center

	&__inner
		max-width: 552px
		width: 552px

	&__buttons
		display: flex
		margin-top: 40px

	&__rating
		display: flex
		align-items: center
		margin-bottom: 8px
		justify-content: space-between

		p
			margin-right: 40px

		svg
			width: 22px
			height: 22px

			path
				fill: $primaryWhite

	&__textarea > div > div
		background: $primaryColor

	&__textarea > div > div:focus-within
		background: $primaryColor
		border: 2px solid $primaryWhite

	&__textarea  > div > div > textarea
		background: $primaryColor !important
		color: $secondaryLightPurple
		font-family: "Gilroy", sans-serif

	&__textarea  > div > div > textarea::placeholder
		color: $secondaryLightPurple


.finishCertificate
	justify-content: space-around

	&__left
		max-width: 552px
		width: 552px
	&__right
		max-width: 552px
		width: 552px

	&__buttons
		display: flex
		margin-top: 40px

	&__image img
		box-shadow: 0px 4px 40px rgba(53, 38, 126, 0.32)
		border-radius: 24px
 
	&__text p 
		margin-bottom: 16px

@media (min-width: 769px) and (max-width: 1180px)
	.finishCertificate
		flex-direction: column
		padding: 24px
		justify-content: center

		&__image 
			margin-bottom: 40px
			img 
 
				margin: auto
				width: 100%

		&__left
			width: 100%

		&__right
			width: 100%
			max-width: 420px

	.finishReview
		padding: 24px

		&__rating
			flex-direction: row
			align-items: start


@media (max-width: 768px)
	.finish
		h3
			font-size: 2.4rem
			line-height: 32px
			font-weight: 700
			margin-bottom: 8px
		p 
			font-size: 1.4rem
			line-height: 18px		
			margin-bottom: 8px

	.finishCertificate
		flex-direction: column
		padding: 24px
		justify-content: center

		&__buttons
			margin-top: 24px

		&__image 
			margin-bottom: 40px
			img 
 
				margin: auto
				width: 100%

		&__left
			width: 100%

		&__right
			width: 100%

	.finishReview
		padding: 24px

		h3 
			margin-bottom: 12px

		&__rating
			flex-direction: column
			align-items: start

		&__buttons
			margin-top: 24px
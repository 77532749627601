@import ../../assets/styles/_variables
@import ../../assets/styles/_mixin

input[type="range"].range
    -webkit-appearance: none
    // margin-right: 15px
    width: calc(100% - 15px)
    height: 8px
    background: $secondaryLightPurple
    border-radius: 4px
    background-image: linear-gradient($primaryColor, $primaryColor)
    background-repeat: no-repeat
    +r(576)
        width: 100%

    /* Input Thumb */
input[type="range"].range::-webkit-slider-thumb
    -webkit-appearance: none
    height: 20px
    width: 20px
    margin-top: -2px
    border-radius: 50%
    background: $primaryColor
    cursor: ew-resize
    box-shadow: 0 0 6px 0 $primaryWhite
    transition: background .3s ease-in-out
    border: 3px solid $primaryWhite
    box-sizing: content-box

input[type="range"].range::-moz-range-thumb
    -webkit-appearance: none
    height: 16px
    width: 16px
    border-radius: 50%
    background: $primaryColor
    cursor: ew-resize
    box-shadow: 0 0 6px 0 $primaryWhite
    transition: background .3s ease-in-out
    border: 3px solid $primaryWhite
    box-sizing: content-box
    

input[type="range"].range::-ms-thumb
    -webkit-appearance: none
    height: 20px
    width: 20px
    border-radius: 50%
    background: $primaryColor
    cursor: ew-resize
    box-shadow: 0 0 2px 0 #555
    transition: background .3s ease-in-out
    border: 3px solid $primaryWhite
    box-sizing: content-box

input[type="range"].range::-webkit-slider-thumb:hover
    background: $primaryColor

input[type="range"].range::-moz-range-thumb:hover
    background: $primaryColor

input[type="range"].range::-ms-thumb:hover
    background: $primaryColor

    /* Input Track */
input[type=range]::-webkit-slider-runnable-track
    -webkit-appearance: none
    box-shadow: none
    border: none
    background: transparent

input[type=range]::-moz-range-track
    -webkit-appearance: none
    box-shadow: none
    border: none
    background: transparent

input[type="range"].range::-ms-track
    -webkit-appearance: none
    box-shadow: none
    border: none
    background: transparent

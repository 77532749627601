@import '../assets//styles/_variables.sass';

.files_wrap {
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 16px 0px 0px;
  cursor: pointer;
  overflow: hidden;
}

.files_wrap a {
  text-decoration: none;
}

.files_data_wrap {
  width: calc(100% - 25px);
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;

}

.files_text_and_icon {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;

  &>svg {
    min-width: 20px;

    path {
      fill: $primaryColor
    }
  }

  &>div {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;
    color: var(--text_color);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.files_text_and_icon svg {
  margin-right: 18px;
  width: 24px;
  height: 24px;
}

.data__text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.link {
  width: 100%;
  display: block;
}

@media (max-width: 768px) {

  .files_text_and_icon {

    &>div {
      font-size: 1.6rem;
    }
  }

  .files_wrap {
    padding-top: 12px;
    width: 100%;
  }

}
@import ../../assets/styles/_variables

.container
    width: 48px
    height: 48px
    position: relative

    svg
        width: 48px
        height: 48px
        transform: rotate(-90deg)
        stroke-linecap: round

        circle
            r: 45%
            cx: 45%
            cy: 45%
            stroke-width: 4px
            width: 100%
            height: 100%
            transform: translate(2px,2px)
            stroke: $tertiaryGreen
            stroke-opacity: 0.12

            &:nth-child(2)
                stroke-opacity: 0.5
                stroke: $tertiaryGreen
                stroke-dasharray: 135

    span
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        font-size: 1.6rem
        color: $primaryText

@import '../assets/styles/_variables.sass'
@import '../assets/styles/_mixin.sass'
button.button_primary.requestAccess 
  width: 100%
  justify-content: center
  border: none
.requestAccess__mobile
  color: $primaryWhite
  font-size: 2rem
  padding: 8px 16px
  +r(768)
    font-size: 1.6rem
  
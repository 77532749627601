@import ../../../assets/styles/_variables

.container
  display: flex
  flex-wrap: wrap
  margin-bottom: 6px
  width: 100%

.wrapper
  border: 2px dashed $secondaryGray
  border-radius: 12px
  box-sizing: border-box
  height: 80px
  width: 100%
  position: relative
  overflow: hidden
  display: flex

  &__active
    border-color: $primaryColor

  &_small
    width: calc(50% - 15px)

label.label
  display: flex
  position: absolute
  justify-content: center
  align-items: center
  top: 0
  left: 0
  bottom: 0
  right: 0
  padding: 14px
  cursor: pointer

  img
    margin-right: 18px

.label__description
  font-size: 2rem
  line-height: 28px
  color: $secondaryDarkGray
  & > b
    font-size: 1.6rem

input.input
  display: none

.image
  path
    fill: $primaryColor

  &_margin
    margin-right: 30px

  &__container
    width: calc(50% - 15px)
    height: 12.81vmax
    border-radius: 16px
    margin-bottom: 10px
    position: relative

.hero
  border-radius: 16px
  height: 12.81vmax
  width: 100%
  margin-bottom: 10px
  position: relative
  object-fit: cover

.deleteButton
  padding: 0
  width: 40px
  height: 40px
  border-radius: 50%
  background-color: #fff
  border: none
  z-index: 1
  position: absolute
  top: 16px
  right: 16px
  display: flex
  align-items: center
  justify-content: center

  &:hover
    cursor: pointer

.attachments
  position: relative
  padding: 12px 34px 12px 20px
  margin-bottom: 10px
  border: 2px solid $secondaryLightPurple
  border-radius: 12px
  width: 100%
  &:last-child
    margin-right: 0

  &__container
    display: flex
    align-items: center

    & > svg
      margin-right: 10px
      path 
        fill: $secondaryDarkGray

    span
      font-size: 2rem

button.attachments__cancel
  width: 24px
  height: 24px
  position: absolute
  top: 50%
  transform: translateY(-50%)
  right: 20px

  svg 
    path 
      fill: $secondaryDarkGray

@media(max-width: 768px)
  .label__description
    font-size: 1.6rem

    &>span
      font-size: 1.2rem

  label.label img 
    margin-right: 14px
    width: 32px
    height: 32px
@import ../../assets/styles/_variables
@import ../../assets/styles/_mixin
.checkbox
    border: 2px solid $secondaryGray
    min-width: 24px
    width: 24px
    height: 24px
    border-radius: 8px
    display: flex
    align-items: center
    justify-content: center
    &:hover
        cursor: pointer
    svg
        display: none
    &__wrapper
        display: inline-flex
        align-items: center
        margin-right: 16px
        input[type="checkbox"]
            display: none
            &:checked
                &+label.checkbox
                    background-color: $primaryColor
                    border: 2px solid $primaryColor
                    svg
                        display: block
                        &>path
                            fill: $primaryWhite
    +r(768)
        min-width: 20px
        width: 20px
        height: 20px
@import "../assets//styles/_variables.sass";

a.course {
    text-decoration: none;
    background: $primaryWhite;
    box-shadow: 0px 4px 16px $shadowColor;
    border-radius: 24px;
    padding: 12px; 
}

.addCourses_content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.addCourses_content_img {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: $secondaryLightPurple;
    border-radius: 12px;
    margin-bottom: 12px;
    overflow: hidden;
    aspect-ratio: 16/9;
}

.course_status_in_progress {
    position: absolute;
    top: 4px;
    right: 4px;
    background: linear-gradient(
            0deg,
            rgba(255, 187, 51, 0.12),
            rgba(255, 187, 51, 0.12)
        ),
        $primaryWhite;
    border-radius: 8px;
    font-size: 1.6rem;
    line-height: 20px;
    color: #ffbb33;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.course_status_completed {
    position: absolute;
    top: 4px;
    right: 4px;
    background: linear-gradient(
            0deg,
            rgba(0, 204, 102, 0.12),
            rgba(0, 204, 102, 0.12)
        ),
        $primaryWhite;
    border-radius: 8px;
    font-size: 1.6rem;
    line-height: 20px;
    color: #00cc66;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.addCourses_content_line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    /*padding-left: 8px;
    padding-right: 8px;*/
}

.addCourses_content_line svg path {
    fill: $secondaryGray;
}

.addCourses_content_line svg {
    width: 16px;
}

.addCourses_content_line_title {
    width: 100%;
    overflow: hidden;
    font-size: 2rem;
    line-height: 24px;
    color: var(--text_color);
    margin-bottom: 24px;
}

.addCourses_content_line_type {
    font-size: 1.2rem;
    color: $secondaryGray;
    margin-bottom: 4px;
    text-transform: capitalize;
}

.addCourses_content_line_text {
    width: 100%;
    overflow: hidden;
    height: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;
    color: $secondaryDarkGray;
}

.addCourses_content_line_modules {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 20px;
    color: $secondaryGray;
}

.addCourses_content_line_modules svg {
    fill: $secondaryGray;
    margin-right: 4px;
}

.addCourses_content_line_modules span {
    margin-right: 16px;
}

.addCourses_content_line_meny {
    position: relative;
}

.meny {
    display: none;
    position: absolute;
    top: 7px;
    right: -17px;
    z-index: 2;
    width: 130px;
    height: 150px;
}

.meny_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0px;

    width: 113px;
    height: 120px;

    background: $primaryWhite;
    box-shadow: 0px 0px 24px $shadowColor;
    border-radius: 16px;
    margin-top: 10px;
}

.meny_wrap a {
    text-decoration: none;
}

.addCourses_content_line_meny:hover .meny {
    display: flex;
}

.meny_wrap div {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;
    color: var(--text_color);
    padding-left: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.colorGreen {
    color: #00cc66;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 16px;
}

.colorOrange {
    color: #ffbb33;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 16px;
}

.colorPurple {
    color: $primaryColor;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 16px;
}

.addTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;
    text-align: center;
    color: $primaryColor;
    margin-top: 16px;
}

.status {
    font-size: 1.6rem;
    position: absolute;
    top: 4px;
    left: 4px;

    &__daysLeft {
        font-size: 1.6rem;
        position: absolute;
        top: 4px;
        left: 4px; 
        background: $secondaryBackground;
        color: $secondaryDarkGray;
        border-radius: 8px;
        padding: 2px 8px;
        display: flex;
        align-items: center;

    }
}

.svg {
    path {
        fill: $primaryColor;
    }
}

.no_bg {
    background-color: transparent;
}

.noItems {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $secondaryDarkGray;
    h3 {
        margin: 40px 0px 16px 0px;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    }

    p {
        font-size: 20px;
        line-height: 24px;
    }
}

@media (min-width: 769px) and (max-width: 1180px) {
    a.course {
        display: flex;
        flex-direction: column;
    }

    .addCourses_content_line:not(:last-child) {
        margin-top: auto;
    }
}

@media (max-width: 768px) {
    .noItems {
        svg {
            width: 260px;
            height: 214px;
        }
        h3 {
            font-size: 2.4rem;
            line-height: 32px;
        }

        p {
            font-size: 1.6rem;
            line-height: 20px;
        }
    }
    .status {
        font-size: 1.4rem;
    }

    .addCourses_content_line {
        margin-bottom: 4px;

        &_title {
            font-size: 2rem;
            line-height: 24px;
            margin-bottom: 16px;
        }

        &_text {
            font-size: 1.6rem;
            line-height: 20px;
        }

        &_modules {
            font-size: 1.6rem;
            line-height: 20px;
        }
    }
}

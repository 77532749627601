@import ../assets/styles/_variables

.title
	line-height: 36px
	font-size: 3rem
	color: $primaryText
	margin-bottom: 24px
.wrapper
	padding: 24px 0
.certificate
	position: relative
	pointer-events: none
	width: max-content
	border-radius: 16px
	overflow: hidden

	&__personName
		position: absolute
		top: 54%
		left: 5%
		font-weight: 500
		font-size: 20px
		line-height: 24px
		color: $primaryColor

	&__courseName
		position: absolute
		top: 65%
		left: 5%
		text-align: left

	&__projectName
		position: absolute
		bottom: 13%
		left: 14%

	&__date
		position: absolute
		bottom: 8%
		left: 14%

.certificates
	width: 100%
	display: grid
	grid-template-columns: repeat(3, 1fr)
	column-gap: 24px
	row-gap: 24px

	&__noItems
		padding-top: 24px

.certificateItem
	background: $primaryWhite
	box-shadow: 0px 4px 16px rgb(107 77 255 / 4%)
	border-radius: 24px
	padding: 24px

	&__lightbox
		position: fixed
		background-color: rgba(21, 18, 31, 0.4)
		width: 100%
		height: 100%
		top: 0
		left: 0
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		z-index: 1000

		&_inner
			max-width: 936px
		&_close
			margin-bottom: 14px
			text-align: right
			margin-right: -14px

			svg	
				cursor: pointer
				width: 14px
				height: 14px
				path 
					fill: $primaryWhite

		img
			max-width: 936px


	&__data
		display: flex
		align-items: center
		justify-content: space-between

	&__title
		font-weight: 500
		font-size: 16px
		line-height: 20px
		margin-right: 16px

	&__download
		cursor: pointer

	&__image
		margin-bottom: 16px
		cursor: pointer

		& .certificate__personName
			top: 52%

		svg
			width: 312px
			height: 221px

	&__download
		svg
			path
				fill: $primaryColor

.certificateModal
	max-width: 600px
	padding: 24px
	height: auto !important
	box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12)
	border-radius: 24px
	background: $primaryWhite
	margin-top: 20px

	&__image
		margin-bottom: 24px
		
		img
			border-radius: 12px
			border: 1px solid $secondaryLightPurple
		svg
			width: 552px
			height: 389px

	&__header
		display: flex
		justify-content: space-between
		padding-bottom: 24px
		border-bottom: 1px solid $secondaryLightPurple
		margin-bottom: 26px

	p
		font-size: 20px
		line-height: 24px
		color: $primaryBlack
		margin-right: 18px

	&__title
		color: $secondaryDarkGray

	&__text
		text-align: left
		margin-bottom: 24px
		font-weight: 600

	&__buttons
		display: flex
		justify-content: right

	&__cancelButton
		color: $primaryColor
		background: transparent
		margin-right: 24px

	&__close
		cursor: pointer

	&__openButton

.certificatePage
	background: $primaryColor
	display: flex
	justify-content: space-around
	align-items: center

	&__title
		font-weight: 700
		font-size: 32px
		line-height: 40px
		color: $primaryText

	&__desc

		p
			font-weight: 500
			font-size: 20px
			line-height: 24px

	&__buttons
		display: flex
		justify-content: space-between

		a
			text-decoration: none
			display: flex
			font-weight: 600
			font-size: 20px
			line-height: 24px
			padding: 12px 24px
			border-radius: 12px

.button
	background: $primaryText
	color: $primaryColor

	&__outlined
		color: $primaryText
		background: $primaryColor
		border: 2px solid $primaryText

.noItems
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	color: $secondaryDarkGray
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%,-50%)
	h3
		margin: 40px 0px 16px 0px
		font-weight: 700
		font-size: 32px
		line-height: 40px

	p
		font-size: 20px
		line-height: 24px

button.download
	border: none
	background: none
	padding: 0px
	&:hover
		cursor: pointer

.certificateItem
		img
			border-radius: 12px
			border: 1px solid $secondaryLightPurple

@media (min-width: 1181px)
	.certificateItem
		img
			width: 936px
 
 

@media (min-width: 789px) and (max-width: 1180px)
	.title
		display: none
	.certificates 
		grid-template-columns: repeat(2, 1fr)

		&__container
			margin-bottom: -24px
			padding-bottom: 24px

	.certificateItem
		img
			width: 648px
		&__lightbox

			&_close
				margin-right: 0px

@media (max-width: 768px)
	.title
		display: none
	.certificates
		grid-template-columns: repeat(1, 1fr)

	.certificateModal
		border-radius: 0px
		height: 100% !important
		margin-top: 0px
		padding-top: 16px

		&__header
			padding-bottom: 16px
			margin-bottom: 20px

		p 
			font-size: 1.4rem
			font-weight: 500
			line-height: 16px

		&__title
			font-size: 1.6rem

		&__text
			margin-bottom: 20px

		&__close 
			display: flex
			align-items: center

			svg 
				width: 11px
				height: 11px

				path 
					fill: $primaryText

		& > div
			height: 100%

		&__form
			height: 100%
			display: flex
			flex-direction: column

		&__buttons
			margin-top: auto

			button:first-child
				margin-right: 16px

		&__image 
			img
				border-radius: 12px
				border: 1px solid $secondaryLightPurple

	p.certificateModal__title
		font-size: 1.6rem

	.noItems 
		svg 
			width: 260px
			height: 214px
		h3 
			font-size: 2.4rem
			line-height: 32px
		p 
			font-size: 1.6rem
			line-height: 20px

	.certificateItem

		&__lightbox
			&_inner
				width: 100%
			&_close
				margin-right: 14px
			img
				width: 100%
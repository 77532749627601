//Primary
$primaryPurple: #6B4DFF
$primaryPurpleHover: #8d77ff
$primaryPurpleActive: #5943cb
$primaryBlack: #232226

$primaryColor: var(--primary_color)
$primaryHoverColor: var(--primaryHoverColor)
$primaryActiveColor: var(--primaryActiveColor)
$primaryWhite: var(--bg_color)
$primaryText: var(--text_color)
//Secondary
$secondaryDarkGray: var(--secondaryDarkGray)
$secondaryGray: var(--secondaryGray)
$secondaryLightPurple: var(--secondaryLightPurple)
$secondaryBackground: var(--secondaryBackground)
$shadowColor: var(--shadowColor)
$strongShadowColor: var(--strongShadowColor)
$primaryColor40percent: var(--primary_color40percent)

$shadow: 0px 4px 16px $shadowColor
$strongShadow: 0px 4px 16px $strongShadowColor

// $secondaryDarkGray: #5B5766
// $secondaryGray: #A4A1B2
// $secondaryLightPurple: #E2E0FF
// $secondaryBackground: #F6F5FF

//Tertiary
$tertiaryGreen: #00CC66
$tertiaryGreenLight: #DCF4EC
$tertiaryGreenHover: #75df76
$tertiaryGreenActive: #41ab42

$tertiaryOrange: #FFBB33
$tertiaryOrangeHover: #fea478
$tertiaryOrangeActive: #ca7044

$tertiaryRed: #FF4E6B
$tertiaryRedHover: #fe7778
$tertiaryRedActive: #ca4344

$tertiaryBlue: #17B4E5
$tertiaryBlueHover: rgba(23, 180, 229, 0.16)

// FONT

$Poppins: 'Poppins', sans-serif
$PoppinsSemiBold: 'PoppinsSemiBold', sans-serif
$PoppinsLight: 'PoppinsLight', sans-serif
$PoppinsBold: 'PoppinsBold', sans-serif
$PoppinsMedium: 'PoppinsMedium', sans-serif

$GilroyBold: 'GilroyBold', sans-serif
$GilroyMedium: 'GilroyMedium', sans-serif
$Gilroy: 'Gilroy', sans-serif
$GilroySemiBold: 'GilroySemiBold', sans-serif
$GilroyLight: 'GilroyLight', sans-serif

$UbuntuBold: 'UbuntuBold', sans-serif
$UbuntuMedium: 'UbuntuMedium', sans-serif
$Ubuntu: 'Ubuntu', sans-serif
$UbuntuSemiBold: 'UbuntuSemiBold', sans-serif
$UbuntuLight: 'UbuntuLight', sans-serif

$RobotoBold: 'RobotoBold', sans-serif
$RobotoMedium: 'RobotoMedium', sans-serif
$Roboto: 'Roboto', sans-serif
$RobotoSemiBold: 'RobotoSemiBold', sans-serif
$RobotoLight: 'RobotoLight', sans-serif

:export
  mainColor: $primaryColor

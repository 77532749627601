@import '../assets/styles/_variables.sass'


.page500
	text-align: center
	padding: 80px 0px 64px
	color: $secondaryDarkGray

	&__button
		display: inline-flex
		justify-content: left
		padding: 10px 22px
		color: $primaryColor
		border-radius: 12px
		text-transform: capitalize
		border-width: 2px
		border-style: solid
		border-color: $primaryColor
		font-size: 2rem
		line-height: 24px
		text-decoration: none
		font-weight: 600

		&:hover
			color: $primaryHoverColor
			border-color: $primaryHoverColor

	h4
		margin: 40px 0px 16px
		font-weight: 700
		font-size: 32px
		line-height: 40px

	p
		font-size: 20px
		line-height: 24px
		margin-bottom: 40px

	&__imageFull
		display: flex
		align-items: flex-end
		position: relative
		justify-content: center

	&__imagePlug
		padding-bottom: 70px
		margin: 0px -20px 0px -10px
		position: relative
		z-index: 2				

	&__imagePlug2
		position: absolute
		bottom: 93px
		left: 522px
@import '../../assets/styles/_variables.sass'

.footer
    &__container
        display: grid
        grid-template-columns: repeat(3,1fr)
        align-items: center
        border-top: 2px solid $secondaryLightPurple
        height: 80px

    &__left
        font-style: normal
        font-size: 1.4rem
        line-height: 16px
        color: $secondaryGray

    &__center
        justify-self: center
        font-size: 1.4rem
        line-height: 16px
        color: $secondaryGray
        &>span
            margin-right: 24px

    &__right
        justify-self: end
        align-self: center
        padding: 0
        margin: 0
        text-decoration: none
        display: flex
        align-items: center
        &>img
            height: 32px

        p
            margin-left: 10px
            color: $secondaryGray
            font-size: 3rem
            letter-spacing: 1px

.sredaFooter
    background: $primaryBlack
    display: flex
    justify-content: center
    align-items: center
    padding: 20px 0px

    p
        color: $secondaryDarkGray
        margin-right: 8px
        font-size: 14px

    svg
        path
            fill: $secondaryDarkGray

@media (max-width: 1180px)
    .footer
        display: none

@media (max-width: 768px)
    .footer
        display: none

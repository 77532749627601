@import '../../assets/styles/_variables.sass';


$switchWidth: 40px;
$btnSize: 12px;
$padding: 4px;


.switcher {
  display: none;

  &__label {
    display: block;
    position: relative;
    box-sizing: border-box;
    width: $switchWidth;
    height: 24px;
    padding: 6px;
    box-shadow: 0px 4px 24px rgba(99, 101, 109, 0.08) inset;
    border-radius: 8px;
    border: 2px solid $secondaryGray;
    transition: .3s ease;

    &:hover {
      cursor: pointer
    }

    &::before {
      content: "";
      width: $btnSize;
      height: $btnSize;
      position: absolute;
      top: $padding;
      left: $padding;
      background: $secondaryGray;
      border-radius: 4px;
      box-shadow: 0px 4px 24px rgba(99, 101, 109, 0.08);
      transition: left .3s ease, transform .1s ease;
    }

    &:active {
      &::before {
        transform: scale(0.9)
      }
    }
  }

  &:checked {
    &+.switcher__label {
      background-color: $primaryColor;
      border-color: $primaryColor;

      &::before {
        content: "";
        left: ($switchWidth - $btnSize - ($padding * 2));
        background-color: $primaryWhite;
      }
    }
  }
}

.label {
  font-size: 1.6rem;
  line-height: 20px;
  color: var(--text_color);
  margin-left: 8px;

  &__wrapper {
    display: inline-flex;
    align-items: center;

  }
}

@media (max-width: 768px) {
  .label__wrapper {
    margin-top: 0px;
  }
  .label {
    font-size: 1.4rem
  }
}
@import "../assets/styles/_variables.sass";

.cards_wrap {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 24px;
}

.grid {
    display: grid;
    grid-template-columns: 4fr 2fr;
    column-gap: 24px;
}

.cards {
    height: fit-content;
    background: $primaryWhite;
    box-shadow: 0px 4px 16px $shadowColor;
    border-radius: 24px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
}

.cardsSmall {
    height: min-content;
    background: $primaryWhite;
    box-shadow: 0px 4px 16px $shadowColor;
    border-radius: 24px;
    padding: 16px 16px 20px;
    position: sticky;
    top: 0;
    margin-bottom: 24px;
    overflow: hidden;
}

.cardsSmall_row:not(:last-child) {
    border-bottom: 1px solid $secondaryLightPurple;
    margin-bottom: 16px;
    padding-bottom: 16px;
}

.cards_position {
    position: absolute;
    z-index: 3;
    top: 329px;
}

.cards_positionTwo {
    position: absolute;
    z-index: 5;
}

.cardTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    cursor: pointer;
}

.cardTitle_left,
.cardTitle_right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $secondaryBackground;
    border-radius: 12px;
    padding: 12px 24px;

    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;
    color: $primaryColor;
}

.cardTitle_left svg {
    margin-right: 10px;
}

.cardTitle_right {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;
    text-align: right;

    color: $secondaryGray;
}

.block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 24px;
    padding-top: 32px;
}

.right {
    justify-content: right;
}

/*************************************/

.products {
    width: 1230px;
    height: calc(100vh - 56px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 24px;
    padding-top: 32px;
}

.tabLine {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 32px;
    position: relative;
}

.tabActive {
    margin-right: 32px;
    padding-bottom: 6px;
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 28px;

    color: #000000;

    border-bottom: 1px solid #000000;
    cursor: pointer;
}

.tab {
    margin-right: 32px;
    padding-bottom: 6px;
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 28px;

    color: #595959;
    border-bottom: 1px solid transparent;
    cursor: pointer;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 16px;

    width: 114px;
    height: 34px;

    background: #000000;

    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 18px;

    color: #ffffff;
    margin-right: 25px;
    cursor: pointer;
}

.buttonBlock {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.form__input {
    width: 100%;
}

.input__title {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 23px;

    color: #595959;
    margin-bottom: 12px;
}

.form__input input,
.form__input select {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;

    width: 632px;
    height: 39px;

    border: 1px solid #000000;
    margin-bottom: 12px;

    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 23px;

    color: #000000;
}

.form__input input.halfInput {
    width: 70%;
    margin-right: 5px;
}

.input_wrap {
    display: flex;
    justify-content: left;
    align-items: center;
}

.form__input select {
    padding: 2px 8px;
}

/**********************/
.form.full {
    width: 100%;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.themes__wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*margin-bottom: 42px;*/
    padding-bottom: 24px;
    border-bottom: 1px solid $secondaryLightPurple;
}

.theme__elem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.precolor {
    width: 48px;
    height: 12px;
}

.theme__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: 2px solid $secondaryGray;
    border-radius: 12px;
}

.theme__button_marging {
    margin-right: 16px;
}

.theme__marker {
    width: 12px;
    height: 12px;

    border-radius: 8px;
    background: $primaryColor;
}

.themes__wrap_sheme {
    width: 100%;
    margin-top: 24px;
}

.sheme__wrap {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 24px;
}

.sheme__wrap:last-child {
    margin-bottom: 0px;
}

.sheme__title {
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 32px;

    color: #595959;
}

.sheme__text {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;
    color: #595959;
}

.sheme__color_data {
    width: 150px;
    display: flex;
    justify-content: left;
    align-items: center;
}

.color__view {
    width: 48px;
    height: 48px;

    margin-right: 16px;
}

.color__name {
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 23px;

    color: #000000;
}

/*******************************/
.stafUsersHeader,
.stafUsersLine {
    display: flex;
    justify-content: left;
    align-items: center;
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 23px;

    color: #000000;
}

.stafUsersHeader {
    color: #a8a8a8;
}

.stafUsersWrapInfo {
    width: 300px;
}

.stafUsersEmail {
    width: 300px;
}

.stafUsersRole {
    width: 300px;
}

.stafUsersOnlineStatus {
    width: 300px;
}

.stafCreateNewUser {
    margin-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 8px;

    border: 1px dashed #000000;

    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 28px;

    color: #000000;
    cursor: pointer;
}

/************************************/
.dragAndDrop {
    width: 100%;
    padding-bottom: 23px;
}

.dragAndDrop_second {
    margin-top: 24px;
}

.dragAndDrop_text {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;

    color: $secondaryDarkGray;
    margin-bottom: 24px;
}

.dragAndDrop_img {
    position: relative;
    width: 250px;
    height: 64px;
    cursor: pointer;
}

.dragAndDrop_icon {
    width: 160px;
    height: 160px;
}

.borderBottom {
    border-bottom: 1px solid $secondaryLightPurple;
}

.dragAndDrop_img input {
    position: absolute;
    opacity: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding-left: 150px;
}

/*****************************/
.themesSvg {
    margin-bottom: 24px;
}

.themes_line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.themes_line:first-child {
    margin-bottom: 24px;
}

.themes_button {
    width: 48px;
    height: 40px;
    border-radius: 12px;
}

.themes_colorPic {
    width: 112px;
    height: 40px;
    border-radius: 12px;
    position: relative;
}

.themes_margin {
    margin-top: 24px;
}

.themes_colorPic input {
    position: absolute;
    opacity: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.colorName {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;
    color: $secondaryDarkGray;
    margin-bottom: 4px;
}

/*********************************/
.radioButton {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 24px;
    /*margin-top: 24px;*/
}

.sheme__wrap {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 24px;
    margin-right: 40px;
    cursor: pointer;
}

.sheme__wrap:last-child {
    margin-bottom: 0px;
}

.theme__marker {
    width: 12px;
    height: 12px;

    border-radius: 8px;
    background: $primaryColor;
}

.theme__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: 2px solid $secondaryGray;
    border-radius: 12px;
}

.theme__button_marging {
    margin-right: 16px;
}

.sheme__title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;
    color: var(--text_color);
}

.courseInfo__img {
    width: 100%;
    height: 186px;
    background: $secondaryLightPurple;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_noBg {
        background-color: transparent;
    }

    & > img {
        border-radius: 12px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.courseInfo__sertif {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid $secondaryLightPurple;
    margin-bottom: 16px;
}

.courseInfo__desk:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $secondaryLightPurple;
}

.courseInfo__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.courseInfo__blockTitle {
    font-style: normal;
    font-size: 2rem;
    line-height: 24px;
    color: var(--text_color);
    margin-bottom: 12px;
}

.courseInfo__blockButton {
    display: none;
    font-style: normal;
    font-size: 2rem;
    line-height: 24px;
    color: $primaryColor;
    cursor: pointer;
    margin-bottom: 12px;

    &:hover {
        color: $primaryHoverColor;
    }
}


.courseInfo__requestSend {
    background: $secondaryGray;
    padding: 12px 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondaryDarkGray;
    margin-bottom: 0px;
}


.courseInfo__showDesc {
    display: inline;
            font-style: normal;
            line-height: 24px;
            color: $primaryColor;
            cursor: pointer;
            font-size: 2rem;
            margin-bottom: 8px;

            &:hover {
                color: $primaryHoverColor;
            }
}

.courseInfo__blockDesk {
    max-height: 200px;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 20px;
    color: $secondaryDarkGray;

    &_showAll {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical; 
    }
}

.courseInfo__startSoon {
    background: $secondaryBackground;
    padding: 12px 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondaryDarkGray
}

.courseInfo__speakers {
    padding-bottom: 16px;
    border-bottom: 1px solid $secondaryLightPurple;
    margin-bottom: 16px;

    &:last-child {
        border-color: transparent;
    }
}

.tabLine_name {
    display: flex;
    justify-content: left;
    align-items: center;

    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;
    color: var(--text_color);
}

.tabLine_name_round {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 56px;
    height: 56px;
    margin-right: 16px;

    background: $secondaryLightPurple;
    border-radius: 26px;

    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 40px;
    text-align: center;
    color: $primaryColor;
}

.tabLine_name_round img {
    border-radius: 26px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dot {
    width: 12px;
    height: 12px;
    background-color: $secondaryLightPurple;
    border-radius: 4px;
    border: none;
    margin-right: 12px;

    &_current {
        background: $primaryColor;
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        cursor: pointer;
    }

    &__group {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.sliderDotActive {
    width: 12px;
    height: 12px;
    background: $primaryColor;
    border-radius: 4px;
    margin-right: 12px;
}

.sliderDotOff {
    width: 12px;
    height: 12px;
    background: $secondaryLightPurple;
    border-radius: 4px;
    margin-right: 12px;
    cursor: pointer;
}

.course__img_svg {
    path {
        fill: $primaryColor;
    }
}

.course_status_in_progress {
    background: linear-gradient(
            0deg,
            rgba(255, 187, 51, 0.12),
            rgba(255, 187, 51, 0.12)
        ),
        $primaryWhite;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;
    color: #ffbb33;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.course_status_completed {
    background: linear-gradient(
            0deg,
            rgba(0, 204, 102, 0.12),
            rgba(0, 204, 102, 0.12)
        ),
        $primaryWhite;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;
    color: #00cc66;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.course_status_not_started {
    background: $secondaryBackground;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;
    color: $secondaryDarkGray;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.procentOfCompleted {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-left: 15px;
}

.procentOfCompleted__text {
    margin-left: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;
    text-align: center;
    color: var(--text_color);
}

.speaker__main {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 12px;
}

.speaker__title {
    font-style: normal;
    font-size: 2rem;
    line-height: 24px;
    color: var(--text_color);
    margin-bottom: 4px;
}

.speaker__subtitle {
    font-style: normal;
    font-size: 1.6rem;
    line-height: 20px;
    color: $secondaryGray;
}

.speaker__bio {
    font-style: normal;
    font-size: 1.6rem;
    line-height: 20px;
    color: $secondaryDarkGray;
    margin-bottom: 12px;
}

.product__type {
    text-transform: capitalize;
}
.product__title {
    font-size: 3.2rem;
    line-height: 40px;
    color: var(--text_color);
    margin-bottom: 4px;

    &_wrapper {
        padding-bottom: 24px;
        padding-top: 24px;
    }
}

.product__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;
    color: $secondaryGray;
    display: flex;
    align-items: center;
    justify-content: left;

    svg {
        width: 16px;
        height: 16px;
        path {
            fill: $secondaryGray;
        }
    }
}

.product__subtitle svg {
    margin-right: 4px;
}

.product__subtitle span {
    margin-right: 25px;
}

.NoItem {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;
    color: $secondaryGray;
}

.tabletMenu {
    display: none;
}




    .modal__header {
        display: flex;
        justify-content: space-between;
        padding: 16px 16px;
        font-size: 1.6rem;
        color: $primaryText;
        border-bottom: 1px solid $secondaryLightPurple;
        margin: 0px -16px;
        margin-bottom: 24px;
        line-height: 20px;
        align-items: center;
    }
    .modal__inner {
        padding: 0px 24px;
    }

    .modal__inner .courseInfo__desk {
        border-bottom: none;
    }

    .modal__inner .courseInfo__img {
        height: auto;
        aspect-ratio: 16/9;
    }
    .modal__cancel {
        width: 11px;
        height: 11px;

        svg path {
            fill: $primaryText;
        }
    }

    div.modalDesc {
        // height: 100%;
        // top: 0px;
        //transform: none;
        // position: unset;
        // margin-left: auto;
        width: 653px;
        background: $primaryWhite;

        border-radius: 24px;
        height: auto;
    }

    .courseInfo__blockDesk_modal {
        display: block;
        text-align: left;
        overflow: scroll;
        &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: inherit;
        }

    &::-webkit-scrollbar-track{
        background: none;
    }

    &::-webkit-scrollbar-track-piece{
        background: none;
    }

    &::-webkit-scrollbar-thumb{
        background: $secondaryLightPurple;
        border-radius: 5px;
        width: 8px;
    }
    }

.path {
    &__detail {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
    }


 
}
   .status {
    font-size: 1.6rem; 
    top: 16px;
    left: 16px;
}

.detail__data {
    color: $primaryText;
    text-align: right;
}

.courseInfo__reviews:not(:last-child) {
  padding-bottom: 16px;
  border-bottom: 1px solid $secondaryLightPurple;
  margin-bottom: 16px;
}

.courseInfo {
  &__details {
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-align: left;
  }
  &__detailsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

@media (min-width: 769px) and (max-width: 1180px) {
    .grid {
        grid-template-columns: 1fr;

        .cardsSmall {
            display: none;
        }
    }

    .product__inner {
        position: relative;

        & > div:first-child > div:first-child {
            margin: 0px;
        }
    }

    .tabletMenu {
        margin-top: 16px;
        display: block;
        width: 100%;

        & > div {
            margin: 0px;
        }
    }

    .product__title_wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .cardsSmall {
        border-radius: 0px;
        padding: 24px;
    }

    .courseInfo {
        &__speakers {
            text-align: left;
        }
    }

    .product__inner {
        & > :global(.mobileHeader) {
            display: none;
        }
    }
    .courseInfo {
        &__showDesc {
            display: inline;
            font-style: normal;
            line-height: 24px;
            color: $primaryColor;
            cursor: pointer;
            font-size: 2rem;
            margin-bottom: 8px;

            &:hover {
                color: $primaryHoverColor;
            }
        }

        &__blockButton {
            display: none;
        }
    }

    .modal__header {
        display: flex;
        justify-content: space-between;
        padding: 16px 16px;
        font-size: 1.6rem;
        color: $primaryText;
        border-bottom: 1px solid $secondaryLightPurple;
        margin: 0px -16px;
        margin-bottom: 16px;
        line-height: 20px;
        align-items: center;
    }
    .modal__inner {
        padding: 0px 16px;
    }

    .modal__inner .courseInfo__desk {
        border-bottom: none;
    }
    .modal__cancel {
        width: 11px;
        height: 11px;

        svg path {
            fill: $primaryText;
        }
    }

    div.modalDesc {
        // height: 100%;
        // top: 0px;
        //transform: none;
        // position: unset;
        // margin-left: auto;
        width: 420px;
        background: $primaryWhite;

        border-radius: 24px;
        height: auto;
    }

    .courseInfo__blockDesk_modal {
        display: block;
    }

    .product__title_wrapper {
        padding-bottom: 16px
    }
}

@media (max-width: 768px) {
    div.modalDesc {
        width: 100%;
        border-radius: 0px;
        height: 100vh;
    }
    .tabletMenu {
        display: block; 
        
        & > div > div > div:first-child {
            display: none;
        }

        & > div > div > div:last-child {
            display: flex;
        }
    }
    .grid {
        grid-template-columns: 1fr;
        padding-top: 12px;

        .cards {
            margin-bottom: -24px;
        }
    }

    .modal__inner {

         .courseInfo__img {
            height: auto;
    }
}

    .courseInfo__img {
        margin-bottom: 16px;
    }

    .speaker__main {
        justify-content: left;
    }

    .cardsSmall {
        border-radius: 0px;
        padding: 20px 16px;
    }

    .cards {
        margin: 0px -24px 24px;
        border-radius: 0px;
    }

    .product {
        &__title {
            font-size: 2.4rem;
            line-height: 32px;
        }
    }

    .product__title_wrapper {
        padding-top: 16px;
        padding-bottom: 0px;
    }

    .product__subtitle {
        font-size: 1.2rem;
    }
    .courseInfo__sertif {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .courseInfo {
        &__blockTitle {
            font-size: 1.6rem;
            margin-bottom: 8px;
        }
        &__blockButton {
            display: none;
        }

        &__showDesc {
            display: inline;
            font-style: normal;
            line-height: 20px;
            color: $primaryColor;
            cursor: pointer;
            font-size: 1.6rem;
            margin-bottom: 8px;

            &:hover {
                color: $primaryHoverColor;
            }
        }

        &__desk {
            margin-bottom: 20px;
        }

        &__speakers {
            text-align: left;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }

    .tabLine_name_round {
        font-size: 2rem;
        width: 48px;
        height: 48px;
        margin-right: 12px;
    }

    .speaker {
        &__main {
            margin-bottom: 0px;
        }

        &__title {
            font-size: 1.6rem;
            line-height: 20px;
        }

        &__subtitle {
            font-size: 1.2rem;
            line-height: 14px;
        }

        &__bio {
            font-size: 1.4rem;
            line-height: 18px;
            margin-bottom: 8px;
            margin-top: 8px;
        }
    }

    .NoItem {
        font-size: 1.6rem;
    }

    .modal__header {
        display: flex;
        justify-content: space-between;
        padding: 16px 16px;
        font-size: 1.6rem;
        color: $primaryText;
        border-bottom: 1px solid $secondaryLightPurple;
        margin: 0px -16px;
        margin-bottom: 16px;
        line-height: 20px;
    }
    .modal__inner {
        padding: 0px 16px;
        background: $primaryWhite;
    }

    .modal__inner .courseInfo__desk {
        border-bottom: none;
    }
    .modal__cancel {
        width: 11px;
        height: 11px;

        svg path {
            fill: $primaryText;
        }
    }

    .procentOfCompleted__text {
        font-size: 1.2rem;
    }

    .courseInfo__blockDesk_modal {
        display: block;
    }
}
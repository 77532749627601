@import "../assets/styles/_variables.sass"

.lang__switch
    position: relative
    color: $secondaryGray
    font-size: 2rem
    cursor: pointer

.submenu
    display: flex
    flex-direction: column
    position: absolute
    border-radius: 12px
    padding: 20px 12px 0px 16px
    background: $primaryWhite
    width: 57px
    z-index: 1
    left: -20px
    top: -22px
    z-index: 6
    box-shadow: 0px 4px 16px rgba(107, 77, 255, 0.04)
    color: $primaryBlack

    &__item
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 8px
        order: 2
        line-height: 24px
        justify-content: center

    &__item:hover
        color: $primaryBlack
        cursor: pointer

    &__firstItem
        order: 1
        border-bottom: 1px solid $secondaryLightPurple
        padding-bottom: 8px
        margin-bottom: 8px



.overlay
    width: 100%
    height: 100%
    top: 0
    left: 0
    background: transparent
    z-index: 5
    position: fixed

@media (max-width: 768px)
    .lang__switch
        font-size: 1.6rem
        position: unset
    .submenu
        right: 5px
        left: unset
        top: -3px

@import ../assets/styles/_variables

.mobileHeader

	&__buttons
		display: flex
		width: 20%
		justify-content: flex-end
	&__text
		width: 40%
		text-align: center
		overflow: hidden
		text-overflow: ellipsis
		display: -webkit-box
		-webkit-line-clamp: 2
		line-clamp: 2
		-webkit-box-orient: vertical
	&__arrow
		width: 20%

		svg
			path
				fill: $secondaryDarkGray

	&__notifications
		margin-right: 18px

	&__title
		display: flex
		justify-content: center
		padding: 16px
		background: $primaryWhite
		border-bottom: 1px solid $secondaryLightPurple
		margin: 0px -16px
		align-items: center

		& > svg
			position: absolute
			left: 24px

		h3
			font-size: 1.6rem
			color: $primaryText
			line-height: 20px

	&__menu
		height: 36px
		display: flex
		align-items: flex-end

.menuLeft
	display: flex
	align-items: center

	svg
		width: 8px
		height: 12px
		margin: 0px 13px

		path
			fill: $secondaryDarkGray

	& > span
		font-size: 1.2rem
		color: $primaryBlack

.modal
	&__cancel
		width: 11px
		height: 11px

		svg
			path
				fill: $primaryText

	&__header
		display: flex
		justify-content: space-between
		padding: 16px
		font-size: 1.6rem
		color: $secondaryDarkGray
		border-bottom: 1px solid $secondaryLightPurple
		margin-bottom: 20px

.headerMobile__item
	display: flex
	align-items: center

	svg
		path
			fill: $secondaryDarkGray

.modal__breadcrumbs > div
	display: block

.mobileHeader__notificationButtonMobile, 	.mobileHeader__notificationButtonTablet
	display: flex
	align-items: center

@media (min-width: 1181px)
	.mobileHeader__title
		display: none
	.mobileHeader__menu
		display: none
	.mobileHeader__notificationButtonMobile
		display: none

	.mobileHeader
		&__tabletBreadcrumbs
			display: none

@media (min-width: 769px) and (max-width: 1180px)
	.menuLeft
		display: block

	.mobileHeader__menu
		display: block

	.mobileHeader
		&__title
			display: flex
			margin: 0px
			justify-content: space-around
			margin: 0px -48px
		&__menu
			display: none
		&__tabletBreadcrumbs > div
			display: block
			margin: 0px -48px
			border-bottom: 1px solid $secondaryLightPurple
	.mobileHeader__notificationButtonMobile
		display: none
	.mobileHeader__notificationButtonTablet
		display: flex
		margin-right: 20px

		svg
			path
				fill: $secondaryDarkGray

@media (max-width: 768px)
	.modal
		border-radius: 24px 24px 0px 0px
		height: auto !important
		margin-top: auto
	.mobileHeader__title
		display: flex
		justify-content: space-between
		position: fixed
		width: 100%
		top: 0
		z-index: 99
	.mobileHeader__menu
		display: flex
	.mobileHeader__notificationButtonMobile
		display: flex
	.mobileHeader__notificationButtonTablet
		display: none

@media (max-width: 576px)

	.mobileHeader
		&__buttons
			width: auto

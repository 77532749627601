@import './_variables.sass'

@mixin card
	padding: 24px
	background: $primaryWhite
	box-shadow: 0px 4px 16px $shadowColor
	border-radius: 24px

@mixin font20
	font-size: 2rem
	line-height: 24px

@mixin font16
	font-size: 1.6rem
	line-height: 24px


 
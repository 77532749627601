@import ../../assets/styles/_variables

.button
  outline: none
  background-color: transparent
  font-size: 2rem
  line-height: 24px
  border: 2px solid transparent

  &:focus
    border-color: $primaryText

  &:hover
    cursor: pointer

  &.primary
    display: flex
    justify-content: left
    padding: 10px 22px
    background: $primaryColor
    border-radius: 12px
    color: $primaryWhite

    &:hover
      background: $primaryHoverColor
      border-color: $primaryHoverColor
      color: $primaryWhite


  &.secondary
    display: flex
    justify-content: left
    padding: 10px 22px
    color: $primaryColor
    border-radius: 12px
    text-transform: capitalize
    border-width: 2px
    border-style: solid
    border-color: $primaryColor
    &:focus
      border-color: $primaryText

    &:hover
      color: $primaryHoverColor
      border-color: $primaryHoverColor

  &.tetriary
    font-style: normal
    border: none
    color: $primaryColor
    text-align: left

    &:hover
      color: $primaryHoverColor
      
    //border: 2px solid $primaryHoverColor
    //&.primary:focus
    //border: 2px solid $primaryColor
//  border-width: 2px
//  border-style: solid
//  border-image: $primaryColor
    //&:active
    //border: 0px solid $primaryActiveColor
button.button.button__disabled
  background-color: $secondaryGray
  color: $secondaryDarkGray
  pointer-events: none

  &:hover
    border-color: transparent
    border-image: none
    background: $secondaryGray
    color: $secondaryDarkGray

@media (max-width: 768px)
    .button
        font-size: 1.6rem

        &.secondary
          justify-content: center
          padding: 8px 16px
          line-height: 20px

        &.primary
          justify-content: center
          padding: 8px 16px
          line-height: 20px
          align-items: center
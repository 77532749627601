@import ../assets/styles/_variables
@import ../assets/styles/_styles
@import ../assets/styles/_mixin

.skills
    text-align: center
    &__container
        display: grid
        grid-template-columns: 1fr 1fr
        column-gap: 24px
        
        +r(1180)
            grid-template-columns: 1fr

    &__left
        @include card 
        +r(1180)
            margin-top: 16px
            margin-bottom: 16px 
    &__right
        @include card
        height: fit-content

        +r(768)
            border-radius: 0
            margin: 0px -16px

    &__title
        font-weight: 700
        font-size: 32px
        line-height: 40px
        margin-bottom: 24px
        margin-top: 24px
        color: $primaryText
        text-align: left

        +r(1180)
            display: none
    &__item
        font-size: 1.6rem
        margin-bottom: 8px
        display: flex 
        column-gap: 8px
    &__header
        display: flex  
        margin-bottom: 8px
        font-size: 1.6rem
        color: $secondaryGray

        & .skills__subject
            color: $secondaryGray   
    &__subject
        width: 50%
        text-align: left
        color: $primaryText
    &__level
        width: 50%
        display: flex
        column-gap: 8px

.skills__item_mobile
    justify-content: start
.skills__item_mobile .skills__subject
    order: 2
    width: unset

.skills__item_mobile .skills__level
    order: 1
    width: unset

.noItems
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    color: $secondaryDarkGray

    h3
        margin: 40px 0px 16px 0px
        font-weight: 700
        font-size: 32px
        line-height: 40px

    p
        font-size: 20px
        line-height: 24px

        